import React from "react";
import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import momentBusiness from 'moment-business-days';
import Helper, { getFloorUsingFloorId, getWkngDaysBySelectedBuilding, getWorkingDayArray, getBuildingTimeZoneByNodeId } from "../../../../Common/Helper";
import ConfirmModal from "../../../../Components/Dialogs/ConfirmDialog/ConfirmModal";
import Card from 'react-bootstrap/Card';
import LoadingOverlay from "../../../../Components/Navigation/LoadingOverlay/LoadingOverlay"
import { RouteComponentProps } from "react-router-dom";
import ViewStatus from "./ViewStatus";
import { appContext } from "../../../../AppContext";
import IbssSvgIcon from "../../../../Components/Icons/SvgIcon/IbssSvgIcon";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import IbssTimePicker from "../../../../Components/Inputs/TimePicker/IbssTimePicker";
import IbssDatePicker from "../../../../Components/Inputs/DatePicker/IbssDatePicker";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import { DateHelper } from "../../../../Common/DateHelper";
import IbssDialog from "../../../../Components/Dialogs/BaseDialog/IbssDialog";
import CostCodesDialog, { CostCodeWithAllocation } from "../../../../Components/Dialogs/CostCodesDialog/CostCodesDialog";
import { IUserRights } from "../../../../Providers.Api/Models.UserApi";
import { DateTime, DurationObjectUnits } from "luxon";
import { ReactComponent as CostCodeIcon } from '../../../../Components/Layout/Sidebar/icons/CostCode.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { IPropsFromState } from "../../../../redux/Interfaces";
import { IBookingPolicyData, IBookingSlots } from "../../../../Providers.Api/BookingPolicies/BookingPolicyRepository";
import { IBookingResources } from "../../../../Providers.Api/Bookings/GetV2BookingEndpoint";
import { ICreateV2Booking_CostCodeAllocation, ICreateV2BookingRequest } from "../../../../Providers.Api/Bookings/CreateV2BookingEndpoint";
import { IUpdateV2BookingRequest } from "../../../../Providers.Api/Bookings/UpdateV2BookingEndpoint";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Button } from '@mui/material';
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import { CateringOrdersFilter, ICateringOrders } from "../../../../Providers.Api/CateringOrders/CateringOrderRepository";
import BookingPartiesPicker, { Attendee } from "../../../../Components/DialogLaunchers/BookingPartiesPicker/BookingPartiesPicker";
import SpaceArrangementsDialog, { ISpaceArrangement } from "../../../../Components/Dialogs/SpaceArrangementsDialog/SpaceArrangementsDialog";
import EquipmentsPicker from "../../../../Components/DialogLaunchers/EquipmentPicker/EquipmentsPicker";
import { BookingHelper } from "../../../../Common/BookingHelper";
import ConfirmBookingDialog from "../../../../Components/Dialogs/ConfirmBookingDialog/ConfirmBookingDialog";
import { CateringMenuStatus, Filter } from "../../../../Providers.Api/CateringMenus/CateringMenuRepository";
import { IPatchCateringOrder } from "../../../../Providers.Api/CateringOrders/PatchCateringOrderEndpoint";
import { BookingSlotHelper } from "../../../../Common/BookingSlotHelper";
import EmployeeOrVisitorPicker, { IFavouriteUser, IOnBehalfOf } from '../../../../Components/DialogLaunchers/EmployeeOrVisitorPicker/EmployeeOrVisitorPicker';
import { IDelegate } from "../../../../Providers.Api/Delegates/GetManyByDelegatorEndpoint";
import IbssFormControl from "../../../../Components/Forms/FormControl/IbssFormControl";
import IbssSwitchLabel from "../../../../Components/Inputs/Switch/IbssSwitchLabel";
import IbssButton from "../../../../Components/Buttons/Button/IbssButton";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { BookingResponse, ISpaceInfo, TagToEventType, ITodayBookings, V2BookingResponse, SpaceIds } from "./DataModels";
import SetupTeardownTimeSummary from "./SetupTeardownTimeSummary";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PagedResponse } from "../../../../Providers.Api/Models";
import BookingTagPicker from "../../../../Components/Lists/Tags/BookingTagPicker";
import ChangeBookingDetailsDialog, { IBookingDetailsApplication } from "../../../../Components/Dialogs/ChangeBookingDetailsDialog/ChangeBookingDetailsDialog";
import IbssToolTip from "../../../../Components/Miscellaneous/Tooltip/IbssToolTip";
import InfoIcon from "../../../../Components/Icons/InfoIcon";
import ExistingBookings, { IBooking } from "./ExistingBookings";
import IbssIconButton from "../../../../Components/Buttons/IconButton/IbssIconButton";
import { SearchSpacesHelper } from "../../Spaces/Search/SearchSpacesHelper";
import SelectSpaceDrawer from "./SelectSpaceDrawer";
import { ReactComponent as MapPinIcon } from '../../../../Components/Layout/Sidebar/icons/MapPin.svg'
import { BookingAttendanceControl } from "../../../Admin/BookingPolicies/Edit/EditBookingPolicy";
import { CateringOrderStatus } from "../../../../Common/Enums";
import SpaceInfoDialogWithCustomInfo from "../../../../Components/Dialogs/SpaceInfoDialogWithCustomInfo/SpaceInfoDialogWithCustomInfo";

class EditBooking extends IbssPage<IProps, State>
{
    private currentDate = DateHelper.fromIsoToJsDate(DateHelper.now().toString());
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }
    private get bookingService() { return appContext().bookingService; }
    private get apiCache() { return appContext().apiCache; }
    private get appState() { return appContext().state; }
    private helper = new Helper()
    private originalBookingStart: Date;
    private originalBookingEnd: Date;
    private originalSpaceId: string;
    private bookOnBehalfOFRef: React.RefObject<EmployeeOrVisitorPicker>
    private userCanAddTags: boolean;
    private userCanReadTag2Event: boolean;
    private userCanReadTags: boolean;
    private userCanCreateBooking: boolean;
    private userCanUpdateBooking: boolean;
    private isOnelens: boolean;
    private searchCriteria = this.session.getFlexSpaceSearchCriteria()

    constructor(props: IProps)
    {
        super(props);
        this.originalBookingStart = new Date();
        this.originalBookingEnd = new Date();
        this.originalSpaceId = '';
        this.userCanAddTags = this.local.hasRight("DATAMODEL.Tag2EventType.Create");
        this.userCanReadTag2Event = this.local.hasRight("DATAMODEL.Tag2EventType.Read");
        this.userCanReadTags = this.local.hasRight("DATAMODEL.TagDefinitions.Read");
        this.userCanCreateBooking = this.local.hasRight("DATAMODEL.Bookings.Create");
        this.userCanUpdateBooking = this.local.hasRight("DATAMODEL.Bookings.Update");
        this.state = new State();
        this.bookOnBehalfOFRef = React.createRef();
        this.isOnelens = window.location.href.includes('operational-services'); 
    }

    public componentDidMount(): void
    {
        this.pageTitle = this.labels.HubLabelSingleBooking;
        const searchCriteria = this.searchCriteria;
        const startTime = searchCriteria.startTime.toJSDate();
        const endTime = searchCriteria.endTime.toJSDate();
        this.setState({ start: startTime, end: endTime, bookingDate: startTime });
        this.getDetails();
        this.getDelegateList();
    }

    private async loadBookingCostCodes(): Promise<void>
    {
        this.setState({ isLoading: true });

        if (this.local.hasRight('API.Bookings.V2') && typeof this.state.costCodeAllocation !== 'string' && !this.state.freeFormCostCode)
        {
            const bookingCostCodes = this.state.costCodeAllocation;

            // make a call to the cost codes api to get more info about cost codes than is available from booking's Cost_Code_Allocation field.
            const costCodeIds = bookingCostCodes.map(i => i.Cost_Code_Id);
            const costCodePromises = costCodeIds.map(id => this.apiClient.costCodes.getCostCodeById(id));
            const costCodes = await Promise.all(costCodePromises);

            const costCodesWithAllocation = costCodes.map(i =>
            {
                return new CostCodeWithAllocation(i, bookingCostCodes.filter(j => j.Cost_Code_Id === i.Cost_Code_Id)[0].Allocation);
            });

            this.setState({
                costCodes: costCodesWithAllocation, //costs codes associated with booking, which has to be displayed on this component.
            });
        }
        else if (typeof this.state.costCodeAllocation !== 'string')
        {
            const bookingCostCodes = this.state.costCodeAllocation;
            this.setState({
                costCodes: bookingCostCodes.map((defaultCostCode) => ({
                    costCode: defaultCostCode.Cost_Code,
                    costCodeDescription: defaultCostCode.Cost_Code,
                    costCodeId: defaultCostCode.Cost_Code_Id,
                    allocation: defaultCostCode.Allocation,
                }))
            });
        }
        else if (typeof this.state.costCodeAllocation !== 'string')
        {
            const bookingCostCodes = this.state.costCodeAllocation;
            this.setState({
                costCodes: (bookingCostCodes as ICreateV2Booking_CostCodeAllocation[]).map((defaultCostCode) => ({
                    costCode: defaultCostCode.Cost_Code,
                    costCodeDescription: defaultCostCode.Cost_Code,
                    costCodeId: defaultCostCode.Cost_Code_Id,
                    allocation: defaultCostCode.Allocation,
                }))
            });
        }

        this.setState({
            isLoading: false,
        });

    }

    private async getDelegateList(): Promise<void>
    {
        const favouriteList: IFavouriteUser[] = [];
        const allDelegates = await this.apiClient.delegates.getPrimaryDelegators();
        const allfavourites = this.local.getUserPreferences();
        const { FavouriteColleagues } = allfavourites;
        FavouriteColleagues.forEach((item) =>
        {
            favouriteList.push({
                email: item.Email,
                firstName: item.FirstName,
                lastName: item.LastName,
            })
        })
        this.setState({
            delegatedBy: allDelegates.value,
            favourites: favouriteList
        })
    }

    private updateBookingCostCodes(updatedCostCodes: CostCodeWithAllocation[]): void
    {
        this.setState({
            costCodes: updatedCostCodes
        })
    }

    private async getDefaultCostCode(): Promise<void> 
    {
        if (this.state.bookingid.length <= 1 && this.state.freeFormCostCode === false)
        {
            const { DefaultBookingCostCodes } = this.local.getUserPreferences()
            this.setState({
                costCodes: DefaultBookingCostCodes.map((defaultCostCode) => ({
                    costCode: defaultCostCode.CostCodeName,
                    costCodeDescription: defaultCostCode.CostCodeDescription,
                    costCodeId: defaultCostCode.CostCodeID.toString(),
                    allocation: parseInt(defaultCostCode.CostCodeApportionment),
                }))
            });
        }
    }

    private getMinutesStepFromPolicy(values: string[]): number
    {
        if (values.length == 0)
        {
            return 1; //Any
        }
        if (values[0] == '0' && values.length == 1)
        {
            return 60;
        }
        if (values[0] != '0' && values.length > 0)
        {
            return parseInt(values[0]);
        }
        else
        {
            return parseInt(values[1]);
        }
    }

    private canBookOnBehalfOf = this.local.hasRight("API.Bookings.BookOnBehalfOf");

    private get hasOnBehalfOfAccess() { return (this.state.delegatedBy.length > 0 || this.canBookOnBehalfOf); }

    private async getDetails(): Promise<void>
    {
        const { match } = this.props;

        this.setState({ isLoading: true })
        let buildingIdMatchParam = match.params.buildingid;
        let buildingId = parseInt(buildingIdMatchParam);

        const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let buildingTimeZone = getBuildingTimeZoneByNodeId(buildingId);

        const getTzDiff = (timeZone = 'UTC', date = this.currentDate) =>
        {

            const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
            const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
            let offset = (tzDate.getTime() - utcDate.getTime()) / 6e4;
            offset = offset / 60;
            let sign = "UTC+";
            if (offset < 0)
            {
                sign = "-";
                offset *= -1;
            }
            let hours = "0" + Math.floor(offset).toString();
            let minutes = "0" + (Math.round(offset % 1 * 60)).toString();
            return sign + hours.substr(hours.length - 2) + ":" + minutes.substr(minutes.length - 2);
        }
        let localTime = ""

        if (buildingTimeZone == browserTz)
        {
            localTime = this.labels.HubLabelLocalTime;
        }
        else
        {
            localTime = getTzDiff(buildingTimeZone);
        }
        this.setState({
            timelabel: localTime
        })

        let bookingData: (BookingResponse | null);
        let spaceIds: string[];
        const spaceData = await this.apiCache.getSpace(buildingId, match.params.spaceId.split(";")[0]);

        if (spaceData?.Node_Id)
        {
            this.setState({
                nodeId: spaceData.Node_Id
            });
        }
        if (match.params.bookingid == null) // new booking
        {
            bookingData = null;
            spaceIds = [match.params.spaceId]; // we expect exactly 1 space ID
        }
        else
        {
            const associatedBookings = await appContext().ibssApiClientV2.v2.byNodeid.bookings.get<PagedResponse<BookingResponse[]>>({
                nodeId: spaceData ? spaceData.Node_Id : 0,
                filter: `Booking_Id eq '${match.params.bookingid}' or Parent_Booking_Id eq '${match.params.bookingid}'`,
                select: BookingResponse
            });

            bookingData = associatedBookings.value.filter(x => x.Booking_Id == match.params.bookingid)[0];
            spaceIds = bookingData.Space_Id.split(";"); // we expect at least one space

            const setupBooking = associatedBookings.value.filter(x => x.Parent_Booking_Relation_Type == 'BookingRelationTypeSetup' && x.Parent_Booking_Id == match.params.bookingid);
            if (associatedBookings.value.filter(x => x.Parent_Booking_Relation_Type == 'BookingRelationTypeSetup' && x.Parent_Booking_Id == match.params.bookingid).length > 0)
            {
                const setupStart = DateTime.fromISO(setupBooking[0].Booking_Start).offsetTimeByNode(bookingData.Node_Id, true);
                const bookingStart = DateTime.fromISO(bookingData.Booking_Start).offsetTimeByNode(bookingData.Node_Id, true);
                this.setState({ setupDuration: bookingStart.diff(setupStart, 'minutes').minutes })
            }

            const teardownBooking = associatedBookings.value.filter(x => x.Parent_Booking_Relation_Type == 'BookingRelationTypeTearDown' && x.Parent_Booking_Id == match.params.bookingid);
            if (associatedBookings.value.filter(x => x.Parent_Booking_Relation_Type == 'BookingRelationTypeTearDown' && x.Parent_Booking_Id == match.params.bookingid).length > 0)
            {
                const teardownEnd = DateTime.fromISO(teardownBooking[0].Booking_End).offsetTimeByNode(bookingData.Node_Id, true);
                const bookingEnd = DateTime.fromISO(bookingData.Booking_End).offsetTimeByNode(bookingData.Node_Id, true);
                this.setState({ teardownDuration: teardownEnd.diff(bookingEnd, 'minutes').minutes })
            }

            if(this.userCanReadTag2Event)
            {
                try
                {
                    const bookingTags = await appContext().ibssApiClientV2.v2.byNodeid.tag2Eventtype.get<PagedResponse<TagToEventType[]>>({
                        nodeId: spaceData ? spaceData.Node_Id : 0,
                        filter: `Record_Id eq '${match.params.bookingid}'`,
                        select: TagToEventType,
                    });
    
                    if (bookingTags.value.length > 0)
                    {
                        const existingTags = bookingTags.value.map(tag => (TagToEventTypeView.fromTagToEventType(tag)));
                        this.setState({ bookingTags: existingTags, selectedTags: existingTags });
                    }
                } catch (error)
                {
    
                }
            }
        }

        const spacesOrNulls = await Promise.all(spaceIds.map(i => this.apiClient.spaces.getV1SpaceById(spaceData ? spaceData.Node_Id : buildingId, i)));
        const spaces = spacesOrNulls.filter(i => i != null) as Space[];
        const primarySpaceId = spaceIds[0];
        const primarySpace = spaces.find(i => i.Space_Id == primarySpaceId);

        if (primarySpace == null)
        {
            throw new Error("Primary space not found.");
        }

        const floorName = getFloorUsingFloorId(primarySpace.Node_Id)
        const spacesWithFloorName = spaces.map(i => ({ ...i, floorName } as ISpaceView));
        const primarySpaceWithFloorName = { ...primarySpace, floorName };
        const policyId = primarySpaceWithFloorName.Booking_Policy_Id;

        this.setState({
            primarySpace: primarySpaceWithFloorName,
            spaceStatus: primarySpace.Space_Status,
            bookingPolicyId: policyId
        });

        if (policyId && policyId != '0')
        {
            const bookingPolicy = await this.apiClient.bookingPolicies.getSpaceBookingPolicy(spaceData ? spaceData.Node_Id : buildingId, primarySpaceId);

           const nextAvailableTimeSlots=  BookingSlotHelper.nextAvailableTimeSlots(bookingPolicy.BookingSlots.BookingStart, bookingPolicy.BookingSlots.BookingEnd, DateTime.fromJSDate(this.state.start), DateTime.fromJSDate(this.state.end));
            this.setState({
                startIntervalMinutes: bookingPolicy.BookingSlots.BookingStart.SpecificMinutes,
                endIntervalMinutes: bookingPolicy.BookingSlots.BookingEnd.SpecificMinutes,
                bookingPolicyDescription: bookingPolicy.Booking_Policy_Description,
                bookingSlotsPolicy: bookingPolicy.BookingSlots ?? ({} as IBookingSlots),
                bookingAttendanceControl: bookingPolicy.BookingAttendanceControl,
                showAvailableTimelotNote: this.searchCriteria.startTime.toISO() !== nextAvailableTimeSlots.start.toISO()|| this.searchCriteria.endTime.toISO() !== nextAvailableTimeSlots.end.toISO(),
                start: nextAvailableTimeSlots.start.toJSDate(),
                end: nextAvailableTimeSlots.end.toJSDate(),
            });
            if (match.params.bookingid == null && (bookingPolicy.BookingSlots.BookingSetupTime > 0 || bookingPolicy.BookingSlots.BookingTearDownTime > 0))
            {
                this.setState({
                    setupDuration: bookingPolicy.BookingSlots.BookingSetupTime,
                    teardownDuration: bookingPolicy.BookingSlots.BookingTearDownTime
                });
            }
        }
        let rightList = this.local.getUserDetails().userRights as IUserRights;
        let rightsApiBookingArr = rightList?.API?.Bookings;
        let spaceFlag = false;

        if (primarySpaceWithFloorName.Space_Class === "Work" && rightsApiBookingArr.includes("BookLinkedSpaceWork"))
        {
            spaceFlag = true;
        }
        else if (primarySpaceWithFloorName.Space_Class === "Support" && rightsApiBookingArr.includes("BookLinkedSpaceSupport"))
        {
            spaceFlag = true;
        }
        else if (primarySpaceWithFloorName.Space_Class === "Amenity" && rightsApiBookingArr.includes("BookLinkedSpaceAmenity"))
        {
            spaceFlag = true;
        }

        if (spaceFlag)
        {
            if (primarySpaceWithFloorName.Space_Setup == 1)
            {
                this.setState({
                    showSpaceArrangement: true
                });
            }
            else if (primarySpaceWithFloorName.Space_Setup == 5)
            {
                this.setState({
                    showSpaceArrangement: true,
                    showSpaceLayout: true
                });
            }
        }

        if (match.params.bookingid != null && bookingData != null)
        {
            this.setState({ bookingid: match.params.bookingid });

            const duration = this.differenceInHoursAndMinutes(DateTime.fromISO(bookingData.Booking_Start).toJSDate(), DateTime.fromISO(bookingData.Booking_End).toJSDate());
            const timeDiff = this.durationToString(duration);

            const filteredBookingPartiesIsVisitors = bookingData.Booking_Parties.filter(i => i.Booking_Visitor === true);
            const filteredBookingPartiesIsNotVisitors = bookingData.Booking_Parties.filter(i => i.Booking_Visitor === false);

            const jsonSpaceLayout = primarySpaceWithFloorName.Space_Layout ? JSON.parse(primarySpaceWithFloorName.Space_Layout) : ""
            const bookingSpaceLayoutName = jsonSpaceLayout && jsonSpaceLayout.Layouts.filter((item: any) => item.Space_Id === bookingData?.Space_Id);
            const spaceArrangementOption = jsonSpaceLayout && jsonSpaceLayout?.Layouts.filter((x: { Name: string; }) => x.Name == bookingSpaceLayoutName[0].Name)[0].SeatingArrangements;

            this.setState({
                spacesData: spacesWithFloorName,
                isLoading: false,
                name: bookingData.Booking_Name,
                description: bookingData.Booking_Description,
                bookingAttendance: bookingData.Booking_Attendance,
                start: DateTime.fromISO(bookingData.Booking_Start).offsetTimeByNode(bookingData.Node_Id, true).toJSDate(),
                end: DateTime.fromISO(bookingData.Booking_End).offsetTimeByNode(bookingData.Node_Id, true).toJSDate(),
                bookingDate: DateTime.fromISO(bookingData.Booking_Start).offsetTimeByNode(bookingData.Node_Id, true).toJSDate(),
                timeDifference: timeDiff,
                firstNameData: bookingData.Booking_Owner_Email,
                onBehalfOf: bookingData.Booking_Owner_Email,
                bookingOwnerEmail: bookingData.Booking_Owner_Email,
                booking_Parties_array: filteredBookingPartiesIsNotVisitors.map(x => { return ({ email: x.Booking_Participant_Email, name: x.Booking_Participant_Name, organisation: x.Booking_Participant_Organisation, resourceId: x.Booking_Resource_Id, visitor: x.Booking_Visitor, type: x.Booking_Participant_Type, backgroundColor: "" }) }),
                booking_Parties_Visitors_array: filteredBookingPartiesIsVisitors.map(x => { return ({ email: x.Booking_Participant_Email, name: x.Booking_Participant_Name, organisation: x.Booking_Participant_Organisation, resourceId: x.Booking_Resource_Id, visitor: x.Booking_Visitor, type: x.Booking_Participant_Type, backgroundColor: "" }) }),
                spaceId: bookingData.Space_Id,
                selectedSpaceLayout: bookingData.Space_Id,
                selectedSpaceLayoutName: jsonSpaceLayout ? bookingSpaceLayoutName[0].Name : "",
                selectedSeatingArrangement: jsonSpaceLayout ? bookingData.Space_Layout : "",
                Space_Layout: jsonSpaceLayout ? jsonSpaceLayout.Layouts : [],
                SpaceArrangementList: jsonSpaceLayout ? spaceArrangementOption : [],
                Space_Setup: primarySpaceWithFloorName.Space_Setup,
                nodeId: primarySpaceWithFloorName.Node_Id ? primarySpaceWithFloorName.Node_Id : buildingId,
                buildingid: buildingId,
                bookingEarlyCheckIn: bookingData.Booking_Early_Checkin,
                costCodeAllocation: bookingData.Cost_Code_Allocation ? bookingData.Cost_Code_Allocation : '',
                viewStatusProps:
                {
                    createdAt: DateTime.fromISO(bookingData._CreatedAt, { zone: 'utc' }).offsetTimeByNode(bookingData.Node_Id, false),
                    createdBy: bookingData._CreatedBy,
                    bookingid: bookingData.Booking_Id,
                    metaExtBookingId: bookingData.Meta_Ext_Booking_Id,
                    Space_Layout: bookingData.Space_Layout,
                    bookingStatus: bookingData.Booking_Status,
                    isactive: bookingData.Booking_IsActive,
                    checkedin: bookingData.Booking_IsCheckedIn,
                    completed: bookingData.Booking_IsCompleted,
                    cancelled: bookingData.Booking_IsCancelled,
                    autocheckin: bookingData.Booking_AutoCheckIn,
                    earlycheckin: bookingData.Booking_IsEarly_Checkin,
                    lastcheckin: bookingData.Booking_IsLate_Checkin,
                    bookingCheckInTime: bookingData.Booking_Checkin_Time ? DateTime.fromISO(bookingData.Booking_Checkin_Time, { zone: 'utc' }).offsetTimeByNode(bookingData.Node_Id, false) : DateTime.invalid('null'),
                    bookingCompletionTime: bookingData.Booking_Completed_Time ? DateTime.fromISO(bookingData.Booking_Completed_Time, { zone: 'utc' }).offsetTimeByNode(bookingData.Node_Id, false) : DateTime.invalid('null'),
                    bookingCancelletionTime: bookingData.Booking_Cancelled_Time ? DateTime.fromISO(bookingData.Booking_Cancelled_Time, { zone: 'utc' }).offsetTimeByNode(bookingData.Node_Id, false) : DateTime.invalid('null'),
                    bookingCancelledBy: bookingData.Booking_Cancelled_By,
                },
                bookingStatus: bookingData.Booking_Status,
                bookingIsApproved: bookingData.Booking_IsApproved,
                tab: parseInt(match.params.tab),
                isOnBehalfOfRight: this.hasOnBehalfOfAccess,
                bookingResponse: bookingData,
                layout: jsonSpaceLayout,
                addOnlineMeetingLink: JSON.parse(bookingData.Online_Meeting).JoinUrl.length > 0,
                bookingEquipment: bookingData.Booking_Resources,
                bookingStart: DateTime.fromISO(bookingData.Booking_Start).offsetTimeByNode(bookingData.Node_Id, true),
                bookingEnd: DateTime.fromISO(bookingData.Booking_End).offsetTimeByNode(bookingData.Node_Id, true),
            })

            this.setEquipmentTotal();
            this.setCateringTotal();
            const isBookingImmutable = BookingHelper.isImmutable(bookingData.Booking_Status);
            this.setState({ isBookingImmutable: isBookingImmutable });

            this.originalBookingStart = this.state.start;
            this.originalBookingEnd = this.state.end;
            this.originalSpaceId = this.state.spaceId;
        }
        else
        {
            let myStart = this.currentDate;

            if (Object.keys(this.props.flexMySearchFilterCriteria).length > 0 && this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal != undefined)
            {
                myStart = this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal
            }

            const myStartDateTime = myStart.toISOString()
            const myStartDate = myStartDateTime.split('T')[0]

            const userPref = this.local.getUserPreferences();

            let startTime = `${this.zeroPad((this.currentDate.getHours()).toString())}:${this.zeroPad((this.currentDate.getMinutes()).toString())}`;

            if (Object.keys(this.props.flexMySearchFilterCriteria).length > 0 && this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal != undefined)
            {
                var date = this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal;
                startTime = DateTime.fromJSDate(date).toFormat("HH:mm")
            }

            let myEnd = this.currentDate
            if (Object.keys(this.props.flexMySearchFilterCriteria).length > 0 && this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal != undefined)
            {
                myEnd = this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal
            }

            const myEndDateTime = myEnd.toISOString()
            const myEndDate = myEndDateTime.split('T')[0]

            let endTime = userPref?.WorkingHoursPrefs?.UserEndTime;

            if (Object.keys(this.props.flexMySearchFilterCriteria).length > 0 && this.props.flexMySearchFilterCriteria.End_Date_For_filter_modal != undefined)
            {
                var date = this.props.flexMySearchFilterCriteria.End_Date_For_filter_modal
                endTime = DateTime.fromJSDate(date).toFormat("HH:mm")
            }

            const myEndTime = new Date(myEndDate + 'T' + endTime);
            let bookingDate: Date | number = myEndTime;

            const getStrtDate: any = getWkngDaysBySelectedBuilding(userPref?.SearchPrefs?.DefaultBuilding);
            const Occ_Wkng_Days_Stt = getStrtDate?.Occ_Wkng_Days_Stt;
            const Occ_Wkng_Days_Stp = getStrtDate?.Occ_Wkng_Days_Stp;

            const workingDayArray = getWorkingDayArray(parseInt(Occ_Wkng_Days_Stt), parseInt(Occ_Wkng_Days_Stp))

            momentBusiness.updateLocale('us', {
                workingWeekdays: workingDayArray
            });

            // if no booking id, startTime used to calculate booking duration is set to user's preferred startTime. 
            startTime = userPref.WorkingHoursPrefs.UserStartTime;

            if (this.currentDate > myEndTime)
            {
                const todayTime: Date | number = this.currentDate;
                bookingDate = momentBusiness(todayTime).nextBusinessDay().toDate();

            }
            const myStartTime = DateHelper.fromIsoToJsDate(myStartDate + 'T' + startTime);

            const duration = this.differenceInHoursAndMinutes(this.state.start, this.state.end);
            const timeDiff = this.durationToString(duration);
            const jsonSpaceLayout = primarySpaceWithFloorName.Space_Layout ? JSON.parse(primarySpaceWithFloorName.Space_Layout) : ""
            const spaceArrangementOption = jsonSpaceLayout && jsonSpaceLayout?.Layouts[0].SeatingArrangements;

            await this.setStateAsync({
                spacesData: spacesWithFloorName,
                isLoading: false,
                isCateringEnable: false,
                timeDifference: timeDiff,
                Space_Layout: jsonSpaceLayout ? jsonSpaceLayout.Layouts : [],
                SpaceArrangementList: jsonSpaceLayout ? spaceArrangementOption : [],
                selectedSpaceLayout: jsonSpaceLayout ? jsonSpaceLayout.Layouts[0].Space_Id : "",
                selectedSpaceLayoutName: jsonSpaceLayout ? jsonSpaceLayout.Layouts[0].Name : "",
                selectedSeatingArrangement: spaceArrangementOption ? spaceArrangementOption[0].Style : "",
                nodeId: primarySpaceWithFloorName.Node_Id ? primarySpaceWithFloorName.Node_Id : buildingId,
                buildingid: buildingId,
                Space_Setup: primarySpaceWithFloorName.Space_Setup,
                spaceId: jsonSpaceLayout ? jsonSpaceLayout.Layouts[0].Space_Id : match.params.spaceId,
                selectedAlternativeSpaceId: jsonSpaceLayout ? jsonSpaceLayout.Layouts[0].Space_Id : match.params.spaceId,
                isOnBehalfOfRight: this.hasOnBehalfOfAccess,
                availabilityCheckRequired: false
            });

            const selectedSeatingArrangement = this.state.Space_Layout?.filter(x => x.Space_Id == this.state.selectedSpaceLayout)[0]?.SeatingArrangements.filter(x => x.Style == this.state.selectedSeatingArrangement)[0];
            if ((selectedSeatingArrangement?.Setup > 0 || selectedSeatingArrangement?.Breakdown > 0) && (this.state.setupDuration == 0 && this.state.teardownDuration == 0))
            {
                this.setState({ availabilityCheckRequired: true, setupDuration: selectedSeatingArrangement?.Setup, teardownDuration: selectedSeatingArrangement?.Breakdown });
            }
        }
        this.getDefaultCostCode();
        this.loadBookingCostCodes();
        this.getSpaceInfo();
        this.getExistingBooking();
    }

    private zeroPad(numberStr: string): string
    {
        return numberStr.padStart(2, "0");
    }

    private closeChangeBookingTimePopup(): void
    {
        this.setState({ showChangeBookingTimePopup: false, isLoadingToSave: false, isShowModalCancel: false });
    }

    private showChangeBookingTimePopup(show: boolean): void
    {
        this.setState({showChangeBookingTimePopup: show});
    }

    private get interruptUpdateWithChangeBookingTimePopup(): boolean
    {
        if(this.state.bookingid === null || this.state.cateringOrder === undefined)
        {
            return false;
        }

        if(this.state.start === this.originalBookingStart)
        {
            return false;
        }

        return true;
    }

    private async submit(): Promise<void>
    {
        // validate form
        let errors = new Array<string>();
        if (!this.state.isStartValid)
        {
            errors.push(this.labels.HubMessageFromIsNotValid);
        }
        if (!this.state.isEndValid)
        {
            errors.push(this.labels.HubMessageToIsNotValid);
        }
        if (errors.length > 0)
        {
            this.alert.show("Error", `<ul>${errors.map(i => `<li>${i}</li>`).join("")}</ul>`, undefined, { messageIsHtml: true });
            return;
        }

        // create / update booking
        this.setState({ isLoadingToSave: true });
        const { match } = this.props;
        const buildingId = parseInt(match.params.buildingid);
        const date = this.state.bookingDate;
        const startDateTime = DateTime.fromJSDate(date).set({ hour: this.state.start.getHours(), minute: this.state.start.getMinutes() }).setZoneByNode(buildingId, true).toUTC().toISO();
        const endDateTime = DateTime.fromJSDate(date).set({ hour: this.state.end.getHours(), minute: this.state.end.getMinutes() }).setZoneByNode(buildingId, true).toUTC().toISO();
        const isNew = (match.params.bookingid == null);

        if (isNew)
        {
            await this.create(this.state.nodeId, startDateTime, endDateTime);
        }
        else
        {
            if(this.interruptUpdateWithChangeBookingTimePopup)
            {
                this.showChangeBookingTimePopup(true);
                return;
            }
            await this.update(this.state.nodeId, this.state.bookingid, startDateTime, endDateTime);
        }
    }

    public async create(nodeid: number, startDate: string, endDate: string): Promise<void>
    {
        const costCodePayload = this.state.costCodes.map(costCode =>
        ({
            Cost_Code: costCode.costCode,
            Cost_Code_Id: costCode.costCodeId,
            Allocation: costCode.allocation,
        }))

        const payload: ICreateV2BookingRequest =
        {
            _CreatedAt: this.state._CreatedAt,
            _CreatedBy: this.state._CreatedBy,
            SpaceId: this.state.spaceId,
            Booking_Start: startDate,
            Booking_End: endDate,
            Booking_Setup_Time: this.state.setupDuration,
            Booking_Tear_Down_Time: this.state.teardownDuration,
            Booking_Name: this.state.name === '' ? 'Booking' : this.state.name,
            Booking_Description: this.state.description,
            BookingAutoCheckin: this.state.viewStatusProps.autocheckin ? 1 : 0,
            DisableExtUpdate: false,
            UseOnBehalfOfCostCodes: this.state.useOnBehalfOfCostCodes,
            Booking_Parties: this.state.booking_Parties_array.concat(this.state.booking_Parties_Visitors_array).map(x =>
            ({
                Booking_Participant_Email: x.email,
                Booking_Participant_Name: x.name,
                Booking_Participant_Organisation: x.organisation,
                Booking_Participant_Type: x.type,
                Booking_Visitor: x.visitor,
                Booking_Resource_Id: '',
            })),
            Cost_Code_Allocation: this.state.useOnBehalfOfCostCodes === 1 ? [] : costCodePayload,
            AddOnlineMeetingLink: this.state.addOnlineMeetingLink ? 1 : 0,
            Booking_Attendance: isNaN(this.bookingAttendance()) ? 0 : this.bookingAttendance(),
        };

        if (this.state.selectedSeatingArrangement)
        {
            payload.Space_Layout = this.state.selectedSeatingArrangement;
        }
        if (this.state.onBehalfOf)
        {
            const onBehalfOfPayload =
            {
                Email: this.state.onBehalfOfData.email,
                First_Name: this.state.onBehalfOfData.firstName,
                Last_Name: this.state.onBehalfOfData.lastName,
                Display_Name: null,
                Company: this.state.onBehalfOfData.company,
                Visit_Id: null,
                IsVisitor: this.state.onBehalfOfData.isVisitor ? true : false
            }
            payload.OnBehalfOf = JSON.stringify(onBehalfOfPayload);
            if (this.state.onBehalfOfData.isVisitor)
            {
                payload.Booking_Parties.push(
                    {
                        Booking_Participant_Email: this.state.onBehalfOfData.email,
                        Booking_Participant_Name: [this.state.onBehalfOfData.firstName, this.state.onBehalfOfData.lastName].join(" "),
                        Booking_Participant_Organisation: this.state.onBehalfOfData.company,
                        Booking_Participant_Type: 2,
                        Booking_Visitor: this.state.onBehalfOfData.isVisitor,
                        Booking_Resource_Id: '',
                    });
            }
        }

        try
        {
            this.setState({ isLoadingToSave: true, isLoading: true });
            const response = await this.bookingService.create(nodeid, payload);

            this.setState({ bookingResponse: response, bookingid: response.Booking_Id, bookingConfirmedModal: true });
        }
        finally
        {
            this.setState({ isLoadingToSave: false, isLoading: false });
        }
    }

    private async updateBooking(nodeId: number, bookingId: string, startDate: string, endDate: string, spaceId: string = this.state.spaceId): Promise<void> 
    {
        const costCodePayload = this.state.costCodes.map(costCode =>
        ({
            Cost_Code: costCode.costCode,
            Cost_Code_Id: costCode.costCodeId,
            Allocation: costCode.allocation,
        }))

        const payload: IUpdateV2BookingRequest =
        {
            SpaceId: spaceId,
            Booking_Start: startDate,
            Booking_End: endDate,
            Booking_Setup_Time: this.state.setupDuration,
            Booking_Tear_Down_Time: this.state.teardownDuration,
            PartyCount: 0,
            Booking_Name: this.state.name === '' ? 'Booking' : this.state.name,
            Booking_Description: this.state.description,
            Booking_IsPrivate: this.state.isPrivate,
            Organisation: "",
            Booking_Parties: this.state.booking_Parties_array.concat(this.state.booking_Parties_Visitors_array).map(x =>
            ({
                Booking_Participant_Email: x.email,
                Booking_Participant_Name: x.name,
                Booking_Participant_Organisation: x.organisation,
                Booking_Participant_Type: x.type,
                Booking_Visitor: x.visitor,
                Booking_Resource_Id: ''
            })),
            Cost_Code_Allocation: this.state.useOnBehalfOfCostCodes === 1 ? [] : costCodePayload,
            DisableExtUpdate: false,
            UseOnBehalfOfCostCodes: this.state.useOnBehalfOfCostCodes,
            AddOnlineMeetingLink: this.state.addOnlineMeetingLink ? 1 : 0,
            Booking_Attendance: isNaN(this.bookingAttendance()) ? 0 : this.bookingAttendance(),
        };

        if (this.state.selectedSeatingArrangement)
        {
            payload.Space_Layout = this.state.selectedSeatingArrangement
        }

        // payload.onBehalfOf has to be explicitly set, so it cannot be empty or null. otherwise the last person who edits a booking ends up becoming the booking owner.
        payload.OnBehalfOf = (this.state.onBehalfOf && this.local.hasRight("API.Bookings.BookOnBehalfOf") ? this.state.onBehalfOf : this.state.bookingOwnerEmail);

        try
        {
            this.setState({ isLoadingToSave: true, isLoading: true });
            const response = await this.bookingService.update(nodeId, bookingId, payload);
            const bookingResponse = { ...response, Booking_Id: this.state.bookingid };
            this.setState({ bookingResponse: bookingResponse });
        }
        finally
        {
            this.setState({ isLoadingToSave: false, isLoading: false });
        }
    }

    private updateBookingDetails(): Promise<void>
    {
        if(this.state.isShowModalCancel)
        {
            // call cancel
            return this.cancelBooking();
        }
        else
        {
            return this.updateBooking(this.state.nodeId, this.state.bookingid, DateTime.fromJSDate(this.state.start).toUtcByNode(this.state.nodeId).toISO(), DateTime.fromJSDate(this.state.end).toUtcByNode(this.state.nodeId).toISO())
        }
    }

    private async update(nodeId: number, bookingId: string, startDate: string, endDate: string): Promise<void>
    {
        // split update into separate updateBooking and updateCateringOrder functions
        await this.updateBooking(nodeId, bookingId, startDate, endDate);
        if (this.state.cateringOrder)
        {
            await this.updateCateringOrder(this.state.cateringOrder, startDate, endDate);
        }
        this.setState({bookingConfirmedModal: true});
    }

    private async updateCateringOrder(order: ICateringOrders, startDate: string, endDate: string ): Promise<void>
    {
        let orderPatchPayload: IPatchCateringOrder = {};
        if (order.Catering_Service_Time.toLocaleDateString() != DateTime.fromJSDate(this.state.bookingDate).toLocaleDateString())
        {
            const dateValue = new Date(this.state.bookingDate);

            const updatedServiceTime = DateTime.fromISO(order.Catering_Service_Time.toISO()).toJSDate();
            updatedServiceTime.setDate(dateValue.getDate());
            updatedServiceTime.setMonth(dateValue.getMonth());

            const updatedCleanUpTime = DateTime.fromISO(order.Catering_Clearing_Time.toISO()).toJSDate();
            updatedCleanUpTime.setDate(dateValue.getDate());
            updatedCleanUpTime.setMonth(dateValue.getMonth());
            orderPatchPayload =
            {
                Catering_Service_Time: DateTime.fromJSDate(updatedServiceTime).setZoneByNode(this.state.buildingid).toISO(),
                Catering_Clearing_Time: DateTime.fromJSDate(updatedCleanUpTime).setZoneByNode(this.state.buildingid).toISO(),
            }
        }
        try
        {
            this.setState({ isLoadingToSave: true, isLoading: true });
            if (order.Menu_Items[0]?.Menu_Id)
            {
                const cateringMenus = await this.apiClient.cateringMenus.getMany(this.state.buildingid, true, false, 100, new Filter({ status: CateringMenuStatus.active, availableFrom: DateTime.fromISO(startDate), availableTo: DateTime.fromISO(endDate) }));
                const hasMenu = cateringMenus.value.map(x => x.Menu_Id).includes(order.Menu_Items[0].Menu_Id);
                if (!hasMenu)
                {
                    orderPatchPayload = { ...orderPatchPayload, Catering_Order_Status: CateringOrderStatus.CancelledCharged };
                }
            }
            if (Object.entries(orderPatchPayload).length > 0)
            {
                await this.apiClient.cateringOrders.patchCateringOrder(this.state.nodeId, order.Order_Id, orderPatchPayload);
            }
        }
        finally
        {
            this.setState({ isLoadingToSave: false, isLoading: false });
        }
    }

    private bookingAttendance(): number 
    {
        const totalAttendees = this.state.booking_Parties_array.length + this.state.booking_Parties_Visitors_array.length;
        const bookingAttendance = (totalAttendees > 0 && this.state.bookingAttendance === 0) ? totalAttendees : this.state.bookingAttendance;
        return bookingAttendance;
    }

    private async saveTageUpdates(): Promise<void>
    {
        this.setState({ savingTagUpdates: true });
        const tagsToAdd = this.state.selectedTags.filter(tag => tag.tagToEventTypeId == "");
        const selectedTagIds = this.state.selectedTags.map(x => x.tagToEventTypeId)
        const tagsIdsToRemove = this.state.bookingTags.map(x => x.tagToEventTypeId).filter(id => !selectedTagIds.includes(id));

        if (tagsToAdd.length > 0)
        {
            await appContext().ibssApiClientV2.v2.byNodeid.tag2Eventtype.post<TagToEventType>({
                nodeId: this.state.nodeId,
                body: tagsToAdd.map(tag => (
                    {
                        Tag_Id: tag.tagId,
                        EventType_Id: tag.eventTypeId,
                        TagType: tag.tagType,
                        Name: tag.name,
                        Value: tag.value,
                        Icon: tag.icon,
                        Node_Id: tag.nodeId,
                        Record_Id: tag.recordId,
                    }
                ))
            });
        }

        for (const id of tagsIdsToRemove)
        {
            await appContext().ibssApiClientV2.v2.byNodeid.tag2Eventtype.byTag2Eventtypeid.delete<TagToEventType>(
                {
                    nodeId: this.state.nodeId,
                    tag2Eventtypeid: id,
                }
            )
        }

        if (tagsToAdd.length > 0 || tagsIdsToRemove.length > 0)
        {
            try
            {
                const bookingTags = await appContext().ibssApiClientV2.v2.byNodeid.tag2Eventtype.get<PagedResponse<TagToEventType[]>>({
                    nodeId: this.state.nodeId,
                    filter: `Record_Id eq '${this.props.match.params.bookingid}'`,
                    select: TagToEventType,
                });

                const existingTags = bookingTags.value.map(tag => (TagToEventTypeView.fromTagToEventType(tag)));
                this.setState({ bookingTags: existingTags, selectedTags: existingTags });

            } catch (error)
            {

            }
        }
        this.setState({ savingTagUpdates: false });
    }

    private async getBookingResources(bookingId: string): Promise<void>
    {
        try
        {

            const bookingResources = await appContext().ibssApiClientV2.v2.byNodeid.bookings.byBookingid.get<V2BookingResponse>({
                nodeId: this.state.nodeId,
                bookingid: bookingId,
                select: V2BookingResponse,
            });
            this.setStateAsync({ bookingEquipment: bookingResources.Booking_Resources });
            this.setEquipmentTotal();
        }
        catch (error)
        {

        }
    }

    private async cancelBooking(): Promise<void>
    {
        const { match } = this.props;
        if (match.params.bookingid == null)
        {
            return;
        }

        this.setState({
            isShowModalCancel: !this.state.isShowModalCancel,
            isLoadingToSave: true,
        });

        this.bookingService.delete(this.state.nodeId, match.params.bookingid).then((res) =>
        {
            const { history } = this.props;
            this.setState({ isLoadingToSave: false, showCancelSuccessPoup: true });

        }).catch((error: any) =>
        {
            this.setState({ isLoadingToSave: false });
        });
    };

    private change = (e: any) =>
    {
        const changeEvent = { [e.target.name]: e.target.value } as Pick<State, keyof State>
        this.setState(changeEvent);
    };

    private cancelDeleteModal = () =>
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        })
    }

    private delete = () =>
    {

    }

    private cancel(): void
    {
        if(this.props.match.params.bookingid)
        {
            this.backToBookingList();
        } else 
        {
            this.props.history.goBack();
        }
    }

    private backToBookingList(): void
    {
        const { history } = this.props;
        const activeUrl = window.location.href;

        if (activeUrl.includes("flex-my-bookings") || activeUrl.includes("flex-find-a-space"))
        {
            history.push("/flex-my-bookings");
        }
        else if (activeUrl.includes("/flex/bookings/primaries"))
        {
            history.push("/flex/bookings/primaries");
        }
        else
        {
            history.push(`/operational-services-bookings/${this.appState.buildingId}/0`);
        }
    }

    private redirectPath(): void
    {
        const { history } = this.props;
        let activeUrl = window.location.href;

        if (activeUrl.includes("flex-my-bookings") || activeUrl.includes("flex-find-a-space") || activeUrl.includes("flex/bookings/primaries"))
        {
            history.push("/flex-home");
        }
        else
        {
            history.push("/operational-services-home");
        }
    };

    private createAnotherBookingRedirectPath(): void
    {
        const { history } = this.props;
        let activeUrl = window.location.href;

        if (activeUrl.includes("flex-my-bookings") || activeUrl.includes("flex-find-a-space"))
        {
            history.push(`/flex/spaces/search?${SearchSpacesHelper.buildQuery()}`);
        }
        else
        {
            history.push(`/one-lens/spaces/search?${SearchSpacesHelper.buildQuery(this.state.buildingid)}`);
        }
    };

    private viewBookingsClicked(): void
    {
        const { history } = this.props;
        let activeUrl = window.location.href;

        if (activeUrl.includes("flex-my-bookings") || activeUrl.includes("flex-find-a-space"))
        {
            history.push("/flex-my-bookings");
        }
        else
        {
            history.push(`/operational-services-bookings/${this.state.buildingid}/0`);
        }
    };

    private handleModal = () =>
    {
        this.setState({
            show: !this.state.show,
        })
    };

    private setNoonTime(): Date
    {
        return DateTime.local().startOf('day').toJSDate();
    }

    private setChecked = (e: any) =>
    {
        if (e.target.name === 'bookingAutoCheckin' && this.state.bookingAutoCheckin === 1)
        {
            this.setState({ bookingAutoCheckin: 0 });
        }
        if (e.target.name === 'bookingAutoCheckin' && this.state.bookingAutoCheckin === 0)
        {
            this.setState({ bookingAutoCheckin: 1 });
        }
    };

    private showCheckAvailability(): void
    {
        const searchCriteria = this.session.getFlexSpaceSearchCriteria();
        const searchStartTime = searchCriteria.startTime;
        const searchEndTime = searchCriteria.endTime;
        if ((DateTime.fromJSDate(this.state.start) < searchStartTime) || (DateTime.fromJSDate(this.state.end) > searchEndTime) || !DateTime.fromJSDate(this.state.bookingDate).hasSame(searchStartTime, 'day') )
        {
            this.setState({ availabilityCheckRequired: true });
        }
        else
        {
            this.setState({ availabilityCheckRequired: false });
        }
    }

    private async startTimeChanged(time: (Dayjs | null)): Promise<void>
    {
        const date = DateTime.fromJSDate(this.state.bookingDate).date();
        const startTime = ((time == null || !time.isValid()) ? DateHelper.null() : DateTime.fromISO(time.toISOString()).set({ year: date.year, month: date.month, day: date.day }));
        let endTime = (!this.state.isEndValid ? DateHelper.null() : DateTime.fromJSDate(this.state.end).set({ year: date.year, month: date.month, day: date.day }));

        if (startTime.isValid && endTime.isValid && startTime > endTime)
        {
            endTime = startTime.clone();
        }

        if (startTime.isValid && endTime.isValid && endTime.diff(startTime, ["minutes"]).minutes < 5)
        {
            const diff = endTime.diff(startTime, ["minutes"]).minutes;
            endTime = endTime.plus({ minutes: 5 - diff });
        }

        const duration = (startTime.isValid && endTime.isValid ? endTime.diff(startTime, ["hours", "minutes"]) : null);
        const durationAsString = (duration == null ? "-" : this.durationToString(duration));

        await this.setStateAsync(
            {
                start: (startTime.isValid ? startTime.toJSDate() : this.state.start),
                end: (endTime.isValid ? endTime.toJSDate() : this.state.end),
                isStartValid: startTime.isValid,
                isEndValid: endTime.isValid,
                timeDifference: durationAsString,
                spaceAvailabilityStatus: null,
                showAlternativeSpaceSelection: false
            });

            this.showCheckAvailability();
    }

    private async endTimeChanged(time: (Dayjs | null)): Promise<void>
    {
        const date = DateTime.fromJSDate(this.state.bookingDate).date();
        let startTime = (!this.state.isStartValid ? DateHelper.null() : DateTime.fromJSDate(this.state.start).set({ year: date.year, month: date.month, day: date.day }));
        const endTime = ((time == null || !time.isValid()) ? DateHelper.null() : DateTime.fromISO(time.toISOString()).set({ year: date.year, month: date.month, day: date.day }));

        const duration = (startTime.isValid && endTime.isValid ? endTime.diff(startTime, ["hours", "minutes"]) : null);
        const durationAsString = (duration == null ? "-" : this.durationToString(duration));

        await this.setStateAsync(
            {
                end: (endTime.isValid ? endTime.toJSDate() : this.state.end),
                isEndValid: endTime.isValid,
                timeDifference: durationAsString,
                spaceAvailabilityStatus: null,
                showAlternativeSpaceSelection: false
            });
        this.showCheckAvailability();
        
        const checkStartTime = this.state.todayBookings.map((item) => item.startTime).sort((a, b) => a.toMillis() - b.toMillis())[0];
        if (endTime.isValid && checkStartTime && checkStartTime.toMillis() <= endTime.toMillis()) 
        {
           this.setState({
                isBookingsAvailable: true,
           })
        } else 
        {
            this.setState({
                isBookingsAvailable: false,
            })
        }
    }

    private async handleDateChange(event: Date): Promise<void>
    {

        const selectedDate = new Date(event);
        let startTime = this.state.start;
        let endTime = this.state.end;

        const datetime = DateTime.fromJSDate(selectedDate);
        const isToday = datetime.hasSame(DateHelper.now(), 'day');

        if (!isToday)
        {
            startTime = DateTime.fromJSDate(this.state.start).set({ year: datetime.year, month: datetime.month, day: datetime.day }).toJSDate();
            endTime = DateTime.fromJSDate(this.state.end).set({ year: datetime.year, month: datetime.month, day: datetime.day }).toJSDate();
        }

        await this.setStateAsync({ bookingDate: selectedDate, spaceAvailabilityStatus: null, availabilityCheckRequired: true, start: startTime, end: endTime, showAlternativeSpaceSelection: false });
        this.showCheckAvailability();
        this.getExistingBooking();
    }

    private handleShowHideViewStatusModal = () =>
    {
        this.setState({
            isViewStatusShowModal: !this.state.isViewStatusShowModal,
        })
    };

    private handleShowHideLayoutModal = () =>
    {
        this.setState({ isShowModalLayout: !this.state.isShowModalLayout });
    };

    private handleShowCancelModal(): void
    {
        this.setState({
            showChangeBookingTimePopup: !this.state.showChangeBookingTimePopup,
            isShowModalCancel: !this.state.isShowModalCancel,
        })
    };

    private handleCostCodeModal(): void
    {
        if (!this.state.isBookingImmutable)
        {
            this.setState((prevState) => ({
                showCostCodeModal: !prevState.showCostCodeModal
            }))
        }
    }

    public updateLayoutSeating(seating: ISpaceArrangement, layout: string, layoutName: string): void
    {
        this.setState({
            selectedSpaceLayout: layout,
            selectedSpaceLayoutName: layoutName,
            spaceId: layout,
            selectedSeatingArrangement: seating.style,
            availabilityCheckRequired: true,
            spaceAvailabilityStatus: null,
            setupDuration: seating.setup ?? 0,
            teardownDuration: seating.breakdown ?? 0
        })
        this.handleShowHideLayoutModal();
    }

    private differenceInHoursAndMinutes(start: Date, end: Date): DurationObjectUnits
    {
        return DateTime.fromJSDate(end).diff(DateTime.fromJSDate(start), ["hours", "minutes"]).toObject();
    }

    private durationToString(duration: DurationObjectUnits)
    {
        let hourDiff = duration?.hours;
        let minuteDiff = duration?.minutes !== undefined ? Math.ceil(duration.minutes) : 0;
        if(minuteDiff == 60)
        {
            hourDiff = hourDiff ? hourDiff + 1 : 0;
            minuteDiff = 0;
        }
        const durationString = `${hourDiff} hr${minuteDiff === 0 ? "" : ` ${minuteDiff}`} ${minuteDiff === 0 ? "" : "min"}`;
        return durationString;
    }

    private async setEquipmentTotal(): Promise<void>
    {
        let i = 0;
        let hearingAidTotal = 0;
        let presetationAidTotal = 0;
        while (i < this.state.bookingEquipment.length)
        {
            let details = null;
            if (this.state.bookingEquipment[i].Booking_Participant_Type == 3)
            {
                details = await apis.getEquipmentDetails(1, this.state.bookingEquipment[i].Booking_Resource_Id);
            }
            if (details && details.data.Equip_Type == 'HearingAid')
            {
                hearingAidTotal = hearingAidTotal + 1;
            }
            if (details && details.data.Equip_Type == 'PresentationAid')
            {
                presetationAidTotal = presetationAidTotal + 1;
            }
            i += 1;
        }

        this.setState({ hearingAidTotal: hearingAidTotal, presentationAidTotal: presetationAidTotal })
    }

    private async setCateringTotal(): Promise<void>
    {
        const { match } = this.props;
        if (match.params.bookingid == null)
        {
            return;
        }

        if (this.state.spacesData[0].Meta_Serv_Reqs_Catering === 1)
        {
            this.setState({ isCateringEnable: true });
            try 
            {
                const filter = new CateringOrdersFilter(
                    {
                        bookingId: match.params.bookingid,
                        statusNot: ['CancelledCharged', 'CancelledNoCharge']
                    });

                const orders = await this.apiClient.cateringOrders.getMany(this.state.spacesData[0].Node_Id, filter);
                if (orders.length > 0)
                {
                    let itemTotal = 0

                    orders.forEach(order =>
                    {
                        if (order.Menu_Items.length > 0)
                        {
                            itemTotal = itemTotal + order.Menu_Items?.map(x => x.QuantityOfItems).reduce((x, y) => { return x + y })
                        }
                    });

                    this.setState({ isCateringEnable: false, cateringTotal: itemTotal, cateringOrder: orders[0] })
                }
                else 
                {
                    this.setState({
                        isCateringEnable: false
                    })
                }
            }
            catch (error) 
            {
                this.setState({
                    isCateringEnable: false
                })
                console.log(error);
            }
        }
    }

    public handleCateringRoute(): void
    {
        const { history } = this.props;
        const activeurl = window.location.href;
        if (this.state.cateringOrder)
        {
            if (activeurl.includes("flex-my-bookings"))
            {
                this.props.history.push("/flex-my-bookings/" + this.state.buildingid + "/catering/" + this.state.bookingid + "/" + this.state.cateringOrder.Order_Id + "/edit")
            }
            else
            {
                this.props.history.push("/operational-services-catering/" + this.state.buildingid + "/catering/" + this.state.bookingid + "/" + this.state.cateringOrder.Order_Id + "/edit")
            }
        }
        else
        {
            history.push('/create-catering-order/' + this.state.buildingid + '/' + this.state.bookingid + '/' + this.state.spaceId.split(';')[0]);
        }
    }

    private getCostCodeLabel(): string
    {
        /* return the string that is returned on the cost code button*/
        const numberOfCostCodes = this.state.costCodes.length;
        if (numberOfCostCodes === 1)
        {
            return this.state.costCodes[0].costCode; //if just one cost code, show the cost code
        }
        else if (numberOfCostCodes > 1)
        {
            return `${this.state.costCodes.length} ${this.labels.HubLabelCostCodes}` // Cost Codes
        }
        else
        {
            return '';
        }
    }

    private async checkIn(): Promise<void> 
    {
        // checkin with either the state.onBehalfOf email address or, if that is an empty string, use the user's email address.
        const checkInEmail = this.state.onBehalfOf || this.state.bookingOwnerEmail;

        try
        {
            this.setState({ isLoading: true });
            const spaceId = this.state.spaceId.split(';')[0];
            let body = {
                BookingOwnerEmail: checkInEmail
            }
           await appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.checkin.patch({
                nodeId: this.state.nodeId,
                id: spaceId,
                body: body,
            })
            this.setState({ isLoading: false });
            this.alert.show("", this.labels.HubLabelBookingCheckInSuccessfully);
            this.props.history.goBack();
        }
        catch
        {
            this.setState({ isLoading: false });
        }
    }

    private async checkOut(): Promise<void> 
    {
        // checkout with either the state.onBehalfOf email address or, if that is an empty string, use the user's email address.
        const checkOutEmail = this.state.onBehalfOf || this.state.bookingOwnerEmail;

        try
        {
            this.setState({ isLoading: true });
            const spaceId = this.state.spaceId.split(';')[0];

            let body = {
                BookingOwnerEmail: checkOutEmail
            }
        await appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.checkout.patch({
                nodeId: this.state.nodeId,
                id: spaceId,
                body: body,
            })
            this.setState({ isLoading: false });
            this.props.history.goBack();
        }
        catch
        {
            this.setState({ isLoading: false });
        }
    }

    private bookingPartiesSubmitted(parties: Attendee[], visitors: Attendee[]): void
    {
        this.setState({
            booking_Parties_array: parties,
            booking_Parties_Visitors_array: visitors
        });
    }


    public showMoreSpaceDetailsSelected(): void
    {
        this.setState({ spaceDetailAccordionExpanded: !this.state.spaceDetailAccordionExpanded });
    }

    public async getSpaceInfo(): Promise<void>
    {
        const spaceInfo = await appContext().ibssApiClientV1.v1.byNodeid.spaces.bySpaceid.spaceInfo.get<ISpaceInfo[]>({
            nodeId: this.state.buildingid,
            spaceid: this.state.primarySpace?.Space_Id ?? ''
        });

        this.setState({
            spaceInfo: spaceInfo.map(x => ({
                imageUrl: x.ImageURI,
                spaceInfoMessage: x.Space_Info_Message,
                title: x.Space_Info_Title,
                actionUrl: x.ActionURI,
                availableFrom: DateHelper.fromIsoByNode(x.Available_From, this.state.buildingid),
                expiryDate: DateHelper.fromIsoByNode(x.Expiry_Date, this.state.buildingid),
            }))
        })
    }

    public async checkSpaceAvailability(): Promise<void>
    {
        this.setState({ checkingSpaceAvailability: true, availabilityCheckRequired: false, spaceAvailabilityStatus: null });

        const buildingId = parseInt(this.props.match.params.buildingid);

        const startDateTime = DateTime.fromJSDate(this.state.bookingDate).set({ hour: this.state.start.getHours(), minute: this.state.start.getMinutes() }).minus({ minutes: this.state.setupDuration }).setZoneByNode(buildingId, true).toUTC().toISO();
        const endDateTime = DateTime.fromJSDate(this.state.bookingDate).set({ hour: this.state.end.getHours(), minute: this.state.end.getMinutes() }).plus({ minutes: this.state.teardownDuration }).setZoneByNode(buildingId, true).toUTC().toISO();

        let selectedSpaceIds = [this.state.spaceId];

        if (this.state.selectedSpaceLayout.length > 0)
        {
            selectedSpaceIds = this.state.selectedSpaceLayout.split(';');
        }
        const spaceFilter = selectedSpaceIds.map(spaceId => `Space_Id eq '${spaceId}'`).join(' or ');

        try
        {
            const spaceInfo = await appContext().ibssApiClientV2.v2.byNodeid.spaces.search.get<PagedResponse<SpaceIds[]>>({
                nodeId: this.state.nodeId,
                filter: `(${spaceFilter}) and AvailableBetween(datetime'${startDateTime}', datetime'${endDateTime}')`,
                timeInclusive: true,
                select: SpaceIds,
            });

            const availableSpaceIds = spaceInfo.value.map(x => x.Space_Id)
            if (selectedSpaceIds.every(x => availableSpaceIds.includes(x)))
            {
                this.setState({ availabilityCheckRequired: false, checkingSpaceAvailability: false, spaceAvailabilityStatus: 'available', showAlternativeSpaceSelection: false });
            }
            else
            {
                this.setState({ availabilityCheckRequired: true, checkingSpaceAvailability: false, spaceAvailabilityStatus: 'unavailable', showAlternativeSpaceSelection: true });
            }
        } catch (error)
        {
            this.setState({ checkingSpaceAvailability: false, availabilityCheckRequired: true, spaceAvailabilityStatus: null });
        }

    }

    public async alternativeSpaceSubmitted(spaceId: string,nodeId: number): Promise<void>
    {
        const { history } = this.props;
        const bookingPolicy = await appContext().ibssApiClientV2.v2.byNodeid.spaces.bySpaceid.bookingPolicy.get<IBookingPolicyData>({
            nodeId: nodeId,
            spaceid: spaceId,
        });

        this.setState({
            showAlternativeSpaceSelection: false,
            spaceAvailabilityStatus: null,
            availabilityCheckRequired: true,
            setupDuration: bookingPolicy.BookingSlots.BookingSetupTime,
            teardownDuration: bookingPolicy.BookingSlots.BookingTearDownTime,
            isDrawerOpen: false,
        });

        await new Promise((resolve) =>
        {
            resolve(history.push(window.location.pathname.replace(this.props.match.params.spaceId, spaceId)));
        });
        await this.updateBooking(nodeId, this.state.bookingid, DateTime.fromJSDate(this.state.start).toUtcByNode(this.state.nodeId).toISO(), DateTime.fromJSDate(this.state.end).toUtcByNode(this.state.nodeId).toISO(),spaceId);
        this.setState({bookingConfirmedModal: true});
    }

    private addTag(tag: TagToEventTypeView): void
    {
        let selectedTags = this.state.selectedTags;
        selectedTags.push(tag)
        this.setState({selectedTags: selectedTags});
    }

    private removeTag(eventTypeId: string, tagId: string): void
    {
        this.setState({selectedTags: this.state.selectedTags.filter( x => x.tagId != tagId )});
    }

    private spaceInfoClicked(): void
    {
        this.setState({ showSpaceInfoDialog: true });
    }

    private async getExistingBooking(): Promise<void>
    {
        const todayBookings: IBooking[] = [];
        const response = await appContext().ibssApiClientV2.v2.byNodeid.spaces.byId.bookingsOnThisDay.get<ITodayBookings[]>({
            id: this.state.primarySpace?.Space_Id ?? "",
            nodeId: this.state.primarySpace?.Node_Id ?? 0,
            calendarDay: DateTime.fromJSDate(this.state.bookingDate).toISODate()
        });
 
        response.forEach((booking) =>
        {
            todayBookings.push({
                id: booking._ID,
                subject: booking.Space_Name,
                spaceId: booking.Space_Id,
                startTime: DateHelper.fromIsoByNode(booking.Booking_Start, this.state.buildingid),
                endTime: DateHelper.fromIsoByNode(booking.Booking_End, this.state.buildingid),
            });
        });
        this.setState({
            todayBookings: todayBookings
        })
    }

    private openDrawer(): void
    {
        this.setState({ isDrawerOpen: true });
    }

    private get meetBookingAttendanceRequirement(): boolean
    {
        // either bookingAttendance field isn't not mandatory or if it is, this.state.bookingAttendance > 0
        return (this.state.bookingAttendanceControl !== BookingAttendanceControl.showAttendanceMandatory || (this.state.bookingAttendanceControl === BookingAttendanceControl.showAttendanceMandatory && this.state.bookingAttendance > 0))
    }

    private get disableSubmitButton(): boolean
    {
        return (this.state.name.trim().length == 0 || this.state.name.length > 50 || this.state.availabilityCheckRequired || this.state.checkingSpaceAvailability || !this.userCanCreateBooking || !this.meetBookingAttendanceRequirement);
    }

    private get disableUpdateButton(): boolean
    {
        return (
            (
                this.state.bookingStatus === "Cancelled" || 
                this.state.bookingStatus === "Completed" || 
                this.state.bookingStatus === "No Show" || 
                this.state.bookingStatus === "Auto Cancelled"
            ) || 
            this.state.name.length === 0 || 
            this.state.name.length > 50 || 
            !this.userCanUpdateBooking || 
            !this.meetBookingAttendanceRequirement
        );
    }
    
    public render(): JSX.Element
    {
        const buildingId = parseInt(this.props.match.params.buildingid);
        const { name, bookingStatus } = this.state;

        const confModalData = this.state.isDelete ? {
            name: "",
            show: this.state.show,
            handleModal: this.cancelDeleteModal,
            okButton: this.delete,
            modalHeading: this.labels.HubLabelConfirmation,
            modalMessage: this.labels.HubLabelDeleteMessage,
        }
            : {
                name: "",
                show: this.state.show,
                handleModal: this.handleModal,
                okButton: () => { this.redirectPath() },
                modalHeading: this.labels.HubLabelConfirmation,
                modalMessage: this.labels.HubLabelConfirmCancelMessage,
            }

        const updateBookingBtnDisable = (this.state.bookingStatus === "Cancelled" || this.state.bookingStatus === "Completed" || this.state.bookingStatus === "No Show" || this.state.bookingStatus === "Auto Cancelled");
        const cancelBookingBtnDisable = (this.state.bookingStatus === "Early Check In" || this.state.bookingStatus === "In Progress" || this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" || this.state.bookingStatus === "No Show" || this.state.bookingStatus === "Auto Cancelled" || this.state.bookingStatus === "Checked In");
        const checkoutAvailable = (this.state.bookingStatus === "In Progress" && this.state.bookingIsApproved === 3) || (this.state.bookingStatus === "In Progress" && this.state.bookingIsApproved === 4) || (this.state.bookingStatus === "Amended" && this.state.viewStatusProps.checkedin) || (this.state.bookingStatus === "Early Check In");
        const swapSpaceAvailable = this.state.bookingStatus === "New" || this.state.bookingStatus === "Approved" || this.state.bookingStatus === "Early Check In" || this.state.bookingStatus === "Awaiting Approval" || this.state.bookingStatus === "Amended" || this.state.bookingStatus === "Checked In" || this.state.bookingStatus === "No Show" || this.state.bookingStatus === "Resolved" || this.state.bookingStatus === "Assigned" || this.state.bookingStatus === "Awaiting Re-allocation";

        const checkInEnabled = (
            (this.state.spaceStatus === "S28" || this.state.spaceStatus === "S38" || this.state.spaceStatus === "S39") &&
            DateTime.fromISO(this.state.bookingEarlyCheckIn) < DateTime.utc() &&
            !cancelBookingBtnDisable  // Disable if the booking is in progress or completed
        );

        const primarySpace = this.state.primarySpace;
    
        return (
            <>
                <Box className="page-height-exct-header">
                    <Box className="rightPanel-main-content">
                        {(this.state.isLoading || this.state.isLoadingToSave) && <LoadingOverlay text={this.labels.HubLabelLoadingText} />}

                        <div className="page-container co-md-4 ">

                            {/* -------------------Start booking Space_Layout & Seating Arrangement Modal------------------- */}
                            <SpaceArrangementsDialog
                                modalOpen={this.state.isShowModalLayout}
                                onClose={() => this.setState({ isShowModalLayout: false })}
                                onSave={(seatingArrangements: ISpaceArrangement, spaceId: string, spaceName: string) => this.updateLayoutSeating(seatingArrangements, spaceId, spaceName)}
                                spaceSetup={this.state.Space_Setup}
                                spaceLayout={this.state.primarySpace?.Space_Layout}
                                selectedLayoutStyle={this.state.selectedSeatingArrangement}
                                spaceId={this.state.spaceId}
                            />
                            {/* -------------------End booking Space_Layout & Seating Arrangement Modal------------------- */}

                            {/* -----------------------------Start View Status Popup--------------------------- */}
                            <IbssDialog
                                aria-modal="true"
                                aria-label="view status modal"
                                open={this.state.isViewStatusShowModal}
                                fullWidth
                                header=
                                {
                                    <>
                                        <label className="modal-heading">{this.labels.HubLabelStatus}</label>
                                    </>
                                }
                                dialogContent=
                                {
                                    <div className="" style={{ height: "545px", overflow: "auto" }} >
                                        <ViewStatus {...this.state.viewStatusProps} />
                                    </div>
                                }
                                onClose={() => this.handleShowHideViewStatusModal()}
                            />
                            {/* -----------------------------End View Status Popup--------------------------- */}

                            {/* ------------------start-cancel success popup in edit page-------------- */}
                            <IbssDialog
                                aria-modal="true"
                                aria-label="cancel booking modal"
                                open={this.state.showCancelSuccessPoup}
                                dialogContent=
                                {
                                    <Box sx={{ flex: 1 }}>
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <div style={{ textAlign: 'center' }}>
                                                    <IbssSvgIcon sx={{ marginLeft: '-22px' }} className="font-18">{Icons.Success}</IbssSvgIcon>
                                                </div>
                                                <p style={{ paddingTop: "5px" }}>{this.labels.funcEditBookingBookingCancelled_S}!</p>
                                            </div>
                                        </div>
                                    </Box>
                                }
                                footer=
                                {
                                    <div className='d-flex w-90' style={{ justifyContent: 'space-between' }}>
                                        <IbssButton
                                            variant="contained"
                                            color='secondary'
                                            onClick={() => this.viewBookingsClicked()}
                                        >
                                            {this.labels.funcBookingHomeView_S}
                                        </IbssButton>
                                        <IbssButton
                                            variant="contained"
                                            onClick={() => this.redirectPath()}
                                        >
                                            {this.labels.funcCreateBookingReturnHome_S}
                                        </IbssButton>
                                    </div>
                                }
                                onClose={() => this.redirectPath()}
                                fullWidth
                            />
                            {/* ------------------end-cancel success popup in edit page-------------- */}

                            {/* ----------------------------- Start Hearing-Aids && Presentation-Aids Popup --------------------------- */}
                            <ConfirmBookingDialog
                                isScheduleView={false}
                                show={this.state.bookingConfirmedModal}
                                onReturnHomeClicked={() => this.redirectPath()}
                                onMakeAnotherBookingClicked={() => this.createAnotherBookingRedirectPath()}
                                cateringReqs={this.state.primarySpace?.Meta_Serv_Reqs_Catering ?? 0}
                                buildingId={this.state.buildingid}
                                floorId={this.state.primarySpace?.Node_Id ?? 0}
                                bookingId={this.state.bookingid}
                                bookingStart={DateTime.fromJSDate(this.state.start).toISO()}
                                bookingEnd={DateTime.fromJSDate(this.state.end).toISO()}
                                bookingName={this.state.name}
                                spaceId={this.state.primarySpace?.Space_Id ?? ""}
                                spaceName={this.state.primarySpace?.Space_Name ?? ""}
                                hearingAidReqs={this.state.primarySpace?.Meta_Serv_Reqs_Hearing ?? 0}
                                presentationAidReqs={this.state.primarySpace?.Meta_Serv_Reqs_Presentation ?? 0}
                                isNewBooking={this.props.match.params.bookingid == null}
                                userCanAddTags={this.userCanAddTags}
                                userCanReadTags={this.userCanReadTag2Event}
                            />
                            {/* -----------------------------End Hearing-Aids&presentation Aid Popup--------------------------- */}

                            {/* -----------------------------Start Cost codes Popup---------------------------*/}

                            <CostCodesDialog
                                buildingId={buildingId}
                                selectedCostCodes={this.state.costCodes}
                                show={this.state.showCostCodeModal}
                                onClose={() => this.handleCostCodeModal()}
                                updateBookingCostCodes={i => this.updateBookingCostCodes(i)}
                                freeFormCostCode={(isFreeForm) => this.setState({ freeFormCostCode: isFreeForm })}
                            />

                            {/* -----------------------------End Cost codes Popup---------------------------*/}

                            {/* -----------------------------Start Change Catering time & Cancel Popup---------------------------*/}
                                {
                                    <ChangeBookingDetailsDialog
                                        show={this.state.showChangeBookingTimePopup}
                                        onClose={()=> this.closeChangeBookingTimePopup()}
                                        displayLocation={this.isOnelens? IBookingDetailsApplication.Onelens: IBookingDetailsApplication.Flex}
                                        buildingId={this.state.buildingid}
                                        isCancelling={this.state.isShowModalCancel}
                                        isSwappingSpace={false}
                                        nodeId={this.state.nodeId}
                                        spaceId={this.state.spaceId}
                                        updateBooking={()=> this.updateBookingDetails()}
                                        bookingStart={this.state.start}
                                        bookingEnd={this.state.end}
                                        originalBookingStart={this.originalBookingStart}
                                        originalSpaceId={this.originalSpaceId}
                                        cateringOrderId={this.state.cateringOrder?.Order_Id} 
                                    />
                                }
                            {/* -----------------------------End Change Catering time & Cancel Popup---------------------------*/}

                            <div className="rightPanel-main-content side">
                                {this.state.show ? (
                                    <ConfirmModal {...confModalData} />
                                ) : ""}

                                {/* Left panel code */}
                                <div style={{ textDecoration: "none", cursor: "default" }}>
                                    <div className="form-panel-left gap-0" aria-label="space-card">
                                        <div className="form-elements">
                                            {primarySpace != null &&
                                                <>
                                                    <div className="row mb-6px">
                                                        <span className="space-Name spacetype">{primarySpace.Space_Type_Label ? primarySpace.Space_Type_Label : primarySpace.Space_Type}</span>

                                                    </div>

                                                    <div className="row mb-6px">
                                                        <span className="space-Name spacetext">{primarySpace.Space_Name} </span>
                                                    </div>
                                                    <div className="row my-9 img-align">
                                                        <div className="align-icons-text">
                                                            <div className="space-icon-item"><img className="spaceIcon" src="/images/Sidebar_Icons/Active_icons/Floor.svg" alt={`${primarySpace.floorName}`} /></div>
                                                            <div className="space-text-item">{primarySpace.floorName}</div>
                                                        </div>
                                                        <div className="align-icons-text">
                                                            <span className="space-icon-item"><img className="spaceIcon" src="/images/Sidebar_Icons/Active_icons/Zone.svg" alt={`Zone ${primarySpace.Meta_Loc_Zone}`} /></span>
                                                            <span className="space-text-item">{primarySpace.Meta_Loc_Zone}</span>
                                                        </div>
                                                        <div className="align-icons-text">
                                                            <span className="space-icon-item"><img className="spaceIcon" src="/images/User Logged in (Fill).svg" alt="Capacity" /></span>
                                                            <span className="space-text-item mr-1">{this.labels.HubLabelCapacity}</span>
                                                            <span className="space-text-item">{primarySpace.Space_Capacity} </span>
                                                            {/* <span className="space-text-item">{item.Space_Setup === 5 ? <img src="/images/Linked Space.svg" alt="icon" /> : ""}</span> */}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-6px">
                                                        <Card className="cardimg ">
                                                            <Card.Img className="" src={primarySpace.ImageURI ? primarySpace.ImageURI : this.state.imagePaths.get(primarySpace.Space_Type)} alt="Space Type Image" />
                                                        </Card>
                                                    </div>
                                                    <div className="row mb-6px">
                                                        {(primarySpace.Meta_Serv_Reqs_Catering === 1 || primarySpace.Meta_Serv_Reqs_AV === 1 || primarySpace.Meta_Serv_Reqs_Hearing > 1) && (
                                                            <div className="col pl-0">

                                                                {primarySpace.Meta_Serv_Reqs_Catering === 1 && (
                                                                    <div className="co-md-4 align-icons-text mb-3px">
                                                                        <span className="space-icon-item back-round">
                                                                            <img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} alt="Catering" />
                                                                        </span>
                                                                        <span className="space-text-item pt-0 ml-10">{this.labels.HubLabelCateringTable} </span>
                                                                    </div>
                                                                )}

                                                                {primarySpace.Meta_Serv_Reqs_AV === 1 && (
                                                                    <div className="co-md-4 align-icons-text mb-3px">
                                                                        <span className="space-icon-item back-round"><img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/ITAV (Fill).svg`} alt="IT / AV" /></span>
                                                                        <span className="space-text-item pt-0 ml-10">{this.labels.HubLabeltvAv}</span>
                                                                    </div>
                                                                )}

                                                                {primarySpace.Meta_Serv_Reqs_Hearing === 1 && (
                                                                    <div className="co-md-4 align-icons-text mb-3px">
                                                                        <span className="space-icon-item back-round">
                                                                            <img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} alt="Hearing Aid" />
                                                                        </span>
                                                                        <span className="space-text-item pt-0 ml-10">{this.labels.HubLabelHearingAid}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}

                                                        <div className="col pl-0">

                                                            {primarySpace.Meta_Serv_Reqs_Presentation === 1 && (
                                                                <div className="co-md-4 align-icons-text mb-3px">
                                                                    <span className="space-icon-item back-round"><img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} alt="Presentation Aids" /></span>
                                                                    <span className="space-text-item pt-0 ml-10">{this.labels.HubLabelPresentationAid}</span>
                                                                </div>
                                                            )}

                                                            {primarySpace.Space_Setup > 1 && (
                                                                <div className="co-md-4 align-icons-text mb-3px">
                                                                    <span className="space-icon-item back-round"><img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Linked Space (Fill).svg`} alt="Linked Space" /></span>
                                                                    <span className="space-text-item pt-0 ml-10">{this.labels.HubLabelLinkedSpaces}</span>
                                                                </div>
                                                            )}

                                                            {primarySpace.Space_Setup >= 1 && (
                                                                <div className="co-md-4 align-icons-text mb-3px">
                                                                    <span className="space-icon-item back-round"><img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Seating Arrangement.svg`} alt="Seating Arrangement" /></span>
                                                                    <span className="space-text-item pt-0 ml-10">{this.labels.HubLabelLayouts}</span>
                                                                </div>
                                                            )}
                                                            <IbssIconButton
                                                                aria-label="info"
                                                                sx={{ padding: 0, color: '#6e6f77', float: 'right'}}
                                                                onClick={() => this.spaceInfoClicked()}
                                                            >
                                                                <SvgIcon fontSize={'small'} component={InfoIcon} color='inherit'></SvgIcon>
                                                            </IbssIconButton>
                                                            <SpaceInfoDialogWithCustomInfo
                                                                isOpen={this.state.showSpaceInfoDialog}
                                                                onClose={() => this.setState({ showSpaceInfoDialog: false })}
                                                                spaceId={this.state.spaceId}
                                                                nodeId={this.state.nodeId}
                                                                buildingId={this.state.buildingid}
                                                            />
                                                        </div>
                                                    </div>
                                                </>}
                                        </div>
                                    </div>
                                </div>

                                {/* Right panel code */}
                                <div className="d-flex flex-wrap">
                                    {/* <Box className="edit-booking-accordion">
                                        <Accordion
                                            expanded={this.state.spaceDetailAccordionExpanded}
                                            onChange={() => this.showMoreSpaceDetailsSelected()}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Box>
                                                    <Typography variant="h6">
                                                        {primarySpace?.Space_Name}
                                                    </Typography>
                                                    {!this.state.spaceDetailAccordionExpanded &&
                                                        <Typography mt={1} variant="body1">
                                                            {this.labels.funcAdvancedBookingSeeSpaceDetail_L}
                                                        </Typography>
                                                    }
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ paddingTop: 0 }}>
                                                {
                                                    <Box>
                                                        <Typography className="mb-1" variant="body1">
                                                            {this.labels.funcAdvancedBookingPolicyDescription_L}
                                                        </Typography>
                                                        <Typography className="mb-1" variant="body2">
                                                            {this.state.bookingPolicyDescription}
                                                        </Typography>
                                                    </Box>
                                                }
                                                {
                                                    primarySpace?.Space_Custom_Info && primarySpace?.Space_Custom_Info.length > 0 &&
                                                    <Box>
                                                        <Typography className="mb-1">
                                                            {this.labels.funcAdvancedBookingAdditionalInformation_S}
                                                        </Typography>
                                                        <Grid container>
                                                            <Grid xs={8}>
                                                                <Typography variant="body2">
                                                                    {primarySpace.Space_Custom_Info}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box> */}
                                    <Card style={{ width: '378px', marginLeft: '20px', marginBottom: '20px', height: 'fit-content', padding: '24px' }}>
                                        <Typography variant="h5">{this.labels.funcEditBookingDateTime_S}</Typography>
                                        <fieldset className="my-2">
                                            <legend style={{ visibility: 'hidden', position: 'absolute', top: '-1000px' }}>{this.labels.funcEditBookingStartTimeEndTime_S}</legend>
                                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                                <Box mr={1}>
                                                    <Typography className="form-input-box-label-date">{this.labels.HubLabelFrom}</Typography>
                                                    <IbssTimePicker
                                                        shouldDisableTime={(time, type) => BookingSlotHelper.isTimeUnavailable(time.toJSDate(), type, 'start', this.state.bookingDate, this.state.start, this.state.end, this.state.bookingSlotsPolicy)}
                                                        className="inputboxmodel-date"
                                                        value={DateTime.fromJSDate(this.state.start)}
                                                        onChange={time => this.startTimeChanged(dayjs(time?.toJSDate()) as (Dayjs | null))}
                                                        minutesStep={this.getMinutesStepFromPolicy(this.state.startIntervalMinutes)}
                                                        slotProps={{ textField: { error: false } }}
                                                        disabled={updateBookingBtnDisable || this.state.viewStatusProps.checkedin}
                                                        ampm={false}
                                                    />
                                                </Box>
                                                <Box mt={3} mx={1}>
                                                    <img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 239.svg`} alt="Right Arrow" />
                                                </Box>
                                                <Box ml={1}>
                                                    <Typography className="form-input-box-label-date">{this.labels.HubLabelTo}</Typography>
                                                    <IbssTimePicker
                                                        shouldDisableTime={(time, type) => BookingSlotHelper.isTimeUnavailable(time.toJSDate(), type, 'end', this.state.bookingDate, this.state.start, this.state.end, this.state.bookingSlotsPolicy)}
                                                        className="inputboxmodel-date"
                                                        value={DateTime.fromJSDate(this.state.end)}
                                                        onChange={time => this.endTimeChanged(dayjs(time?.toJSDate()) as (Dayjs | null))}
                                                        minutesStep={this.getMinutesStepFromPolicy(this.state.endIntervalMinutes)}
                                                        disabled={updateBookingBtnDisable}
                                                        slotProps={{ textField: { error: false } }}
                                                        ampm={false}
                                                    />
                                                </Box>
                                            </Box>
                                            {this.state.showAvailableTimelotNote &&
                                                <Box mt={2} sx={{ bgcolor: 'var(--ui-light-pastel-blue)', color: 'primary.secondaty', p: 1 }}>   
                                                    <Typography variant="body2">{this.labels.funcNextAvailableTimeSlot_D}</Typography>
                                                </Box>
                                            }   
                                            {this.state.isBookingsAvailable &&  <Typography variant="body2" mt={2}>{this.labels.funcToTimeForNextBooking_D}</Typography>}
                                        </fieldset>
                                        <Divider className="my-1"/>

                                        <div className="d-flex justify-content-between">
                                            <div className="icon-text-inline pl-0">
                                                <IbssDatePicker
                                                    value={DateTime.fromJSDate(this.state.bookingDate)}
                                                    onChange={event => this.handleDateChange(event?.toJSDate() as Date)}
                                                    disabled={updateBookingBtnDisable || this.state.viewStatusProps.checkedin}
                                                    minDate={DateHelper.now()}
                                                    slotProps={{ textField: { error: false }, inputAdornment: { sx: { padding: '0px', marginLeft: '0px' } } }}
                                                    shouldDisableDate={(date: unknown) => BookingSlotHelper.isDateUnavailable(date as unknown as DateTime, this.state.bookingSlotsPolicy)}
                                                />
                                            </div>
                                            <div className="icon-text-inline">
                                                <span className="space-icon-item"><img style={{ height: '18px', width: '18px', paddingRight: '5px' }} src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Time (Fill).svg`} alt="icon" /></span>
                                                <span className="timeDate space-text-item pb-4px">{this.state.timeDifference}</span>
                                            </div>
                                            <div className="icon-text-inline">
                                                <span className="space-icon-item"><img style={{ height: '18px', width: '18px', paddingRight: '5px' }} src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Timezone (Fill).svg`} alt="icon" /></span>
                                                <span className="timeDate space-text-item pb-4px">{this.state.timelabel}</span>
                                            </div>
                                        </div>
                                        <Divider className="my-1"/>
                                        {this.state.showSpaceLayout && (
                                            <>
                                                <Button fullWidth sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} className={`row attendes ${this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? "" : "cp"}`} onClick={this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? () => { } : () => this.handleShowHideLayoutModal()}>
                                                    <div className="icon-text-inline pl-0">
                                                        <span className="space-icon-item">
                                                            <img alt="linked space" className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Linked Space (Fill).svg`} />
                                                        </span>
                                                        <span className="space-text-item col-text">{this.labels.HubLabelLinkedSpaces}</span>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="space-text-item mr-3">{this.state.selectedSpaceLayoutName && this.state.selectedSpaceLayoutName}</div>
                                                        <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                            {Icons.RightArrowIcon}
                                                        </IbssSvgIcon>
                                                    </div>
                                                </Button>
                                                <Divider className="my-1"/>
                                            </>
                                        )}

                                        {this.state.showSpaceArrangement && (
                                            <>
                                                <Button fullWidth sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} className={`row attendes ${this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? "" : "cp"}`} onClick={this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? () => { } : () => this.handleShowHideLayoutModal()}>
                                                    <div className="icon-text-inline pl-0">
                                                        <span className="space-icon-item">
                                                            <img alt="Seating Arrangement" className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Seating Arrangement.svg`} />
                                                        </span>
                                                        <span className="space-text-item col-text">{this.labels.HubLabelLayouts}</span>
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="space-text-item mr-3">{this.state.selectedSeatingArrangement && this.state.selectedSeatingArrangement}</div>
                                                        <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                            {Icons.RightArrowIcon}
                                                        </IbssSvgIcon>
                                                    </div>
                                                </Button>
                                                <Divider className="my-1"/>
                                            </>
                                        )}
                                        {/* ------------------schedule view popup in edit page-------------- */}
                                        {this.state.primarySpace?.Node_Id && this.state.primarySpace?.Space_Id &&
                                        <ExistingBookings
                                            selectedDate={DateTime.fromJSDate(this.state.bookingDate)}
                                            buildingId={this.state.buildingid}
                                            floorId={this.state.primarySpace?.Node_Id}
                                            spaceId={this.state.primarySpace?.Space_Id ?? ""}
                                            spaceName={this.state.primarySpace?.Space_Name ?? ""}
                                            todayBookings={this.state.todayBookings}
                                        />
                                        }
                                        {/* ------------------Schedule view popup in edit page-------------- */}
                                        {
                                            this.state.spaceAvailabilityStatus == 'available' && !this.state.checkingSpaceAvailability &&
                                            <div className="mb-1 d-flex" style={{ alignItems: 'center' }}>
                                                <IbssSvgIcon fontSize='medium' className="mr-1" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                    <CheckCircleIcon color="success" />
                                                </IbssSvgIcon>
                                                <div style={{ fontSize: '15px' }}>
                                                    {this.labels.funcSpaceAvailableMessage_S}
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.availabilityCheckRequired && !this.state.bookingid &&
                                            <div>
                                                <IbssButton
                                                    variant="contained"
                                                    fullWidth
                                                    size="large"
                                                    disabled={!this.state.availabilityCheckRequired}
                                                    onClick={() => this.checkSpaceAvailability()}
                                                >
                                                    {
                                                        this.state.spaceAvailabilityStatus == 'unavailable' &&
                                                        <IbssSvgIcon fontSize='medium' className="mr-2" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                            <CancelIcon color="error" />
                                                        </IbssSvgIcon>
                                                    }
                                                    {this.labels.funcBookingCreateCheckAvailability_S}
                                                </IbssButton>
                                                <div className="mt-1 mb-1">
                                                    {
                                                        this.state.selectedSeatingArrangement &&
                                                        <SetupTeardownTimeSummary
                                                            setup={DateTime.fromJSDate(this.state.start).minus({ minutes: this.state.setupDuration })}
                                                            start={DateTime.fromJSDate(this.state.start)}
                                                            end={DateTime.fromJSDate(this.state.end)}
                                                            teardown={DateTime.fromJSDate(this.state.end).plus({ minutes: this.state.teardownDuration })}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {this.state.buildingid &&
                                            <SelectSpaceDrawer 
                                                open={this.state.isDrawerOpen}
                                                closeClicked={() => this.setState({ isDrawerOpen: false })}
                                                buildingId={this.state.buildingid}
                                                floorId={this.state.nodeId}
                                                spaceTypeId={primarySpace?.Space_Type ?? ''}
                                                minCapacity={primarySpace?.Space_Capacity ?? 0}
                                                requiresCatering={this.state.spacesData[0]?.Meta_Serv_Reqs_Catering === 1}
                                                requiresAudioVisual={this.state.spacesData[0]?.Meta_Serv_Reqs_AV === 1}
                                                requiresPresentationAids={this.state.spacesData[0]?.Meta_Serv_Reqs_Presentation === 1}
                                                requiresHearingAids={this.state.spacesData[0]?.Meta_Serv_Reqs_Hearing === 1}
                                                selectedSpaceId={this.state.selectedAlternativeSpaceId}
                                                availableFrom={DateTime.fromJSDate(this.state.start)}
                                                availableTo={DateTime.fromJSDate(this.state.end)}
                                                alternativeSpaceSubmitted={(spaceId,nodeId) => this.alternativeSpaceSubmitted(spaceId,nodeId)}
                                            />
                                        }
                                    </Card>
                                    <Box>
                                        <Card style={{ width: '378px', marginLeft: '20px', height: 'fit-content', padding: '24px', marginBottom: '20px' }}>
                                            <Typography variant="h5">{this.labels.funcBookingCreateBookingOptions_L}</Typography>
                                            <div className="mt-3">
                                                <IbssTextField
                                                    required
                                                    fullWidth
                                                    label={this.labels.HubLabelBooking}
                                                    name="name"
                                                    value={name}
                                                    onChange={this.change}
                                                    variant="standard"
                                                    disabled={bookingStatus === "Completed" || bookingStatus === "Cancelled" ? true : false}
                                                />
                                            </div>
                                            {name.length > 50 && <Typography sx={{ mt: 1 }} color="error">{this.labels.HubLabelCharacterLimit}</Typography>}
                                            <div className="mt-3">
                                                <IbssTextField
                                                    value={this.state.description}
                                                    name="description"
                                                    onChange={this.change}
                                                    label={this.labels.HubLabelAddDescription}
                                                    size="small"
                                                    variant="standard"
                                                    fullWidth
                                                    disabled={updateBookingBtnDisable}
                                                />
                                            </div>
                                            {this.state.bookingAttendanceControl !== BookingAttendanceControl.hideAttendance &&
                                            <div className="mt-3">
                                                <IbssTextField
                                                    value={this.bookingAttendance()}
                                                    type='number'
                                                    onChange={e => this.setState({ bookingAttendance: parseInt(e.target.value) })}
                                                    disabled={updateBookingBtnDisable || this.state.viewStatusProps.checkedin}
                                                    label={
                                                        <>
                                                            {this.labels.funcBookingAttendance_S}
                                                            <IbssToolTip title={this.labels.funcBookingAttendanceToolTip_L} arrow>
                                                                <Box component="span" ml={2}>
                                                                    <InfoIcon />
                                                                </Box>
                                                            </IbssToolTip>
                                                        </>
                                                    }
                                                    size="small"
                                                    variant="standard"
                                                    fullWidth
                                                    inputProps={{ min: 0, pattern: '[0-9]*' }}
                                                    required={this.state.bookingAttendanceControl === BookingAttendanceControl.showAttendanceMandatory}
                                                />
                                            </div>
                                            }
                                            {
                                                primarySpace && primarySpace.Meta_Ext_Booking_System == 1 &&
                                                <>
                                                    <Box>
                                                        <div className="d-flex mt-1">
                                                            <IbssFormControl>
                                                                <IbssSwitchLabel
                                                                    id="onlineMeeting"
                                                                    checked={this.state.addOnlineMeetingLink}
                                                                    onChange={e => this.setState({ addOnlineMeetingLink: e.target.checked })}
                                                                />
                                                            </IbssFormControl>
                                                            <span style={{ marginLeft: '-15px' }} className="space-text-item col-text">
                                                                {this.labels.HubLabelOnlineMeetingLink}
                                                            </span>
                                                        </div>
                                                        <Divider className="my-1"/>
                                                    </Box>
                                                </>
                                            }
                                            {this.hasOnBehalfOfAccess && (
                                                <EmployeeOrVisitorPicker
                                                    ref={this.bookOnBehalfOFRef}
                                                    onSubmit={(onBehalfOf: string, onBehalfOfData: IOnBehalfOf, autoCheckin: boolean, useOnBehalfOfCostCodes: number) => this.setState({
                                                        onBehalfOf: onBehalfOf,
                                                        onBehalfOfData: onBehalfOfData,
                                                        useOnBehalfOfCostCodes: useOnBehalfOfCostCodes,
                                                        viewStatusProps: {
                                                            ...this.state.viewStatusProps,
                                                            autocheckin: autoCheckin
                                                        }
                                                    })}
                                                    firstNameData={this.state.firstNameData}
                                                    onBehalfOfData={this.state.onBehalfOfData}
                                                    delegatedBy={this.state.delegatedBy}
                                                    favourites={this.state.favourites}
                                                    BookingId={this.state.bookingid}
                                                    buildingId={buildingId}
                                                    disabled={updateBookingBtnDisable || this.state.viewStatusProps.checkedin}                                            
                                            />
                                            )}
                                            {this.state.spacesData[0]?.Space_Capacity > 1 && <>
                                                {this.state.bookingid ?
                                                    <>
                                                        {this.state.isBookingImmutable ?
                                                            <BookingPartiesPicker onSubmit={(parties, visitors) => this.bookingPartiesSubmitted(parties, visitors)} disabled={updateBookingBtnDisable || this.state.viewStatusProps.checkedin}/>
                                                            :
                                                            <BookingPartiesPicker bookingParties={this.state.booking_Parties_array} bookingPartyVisitors={this.state.booking_Parties_Visitors_array} onSubmit={(parties, visitors) => this.bookingPartiesSubmitted(parties, visitors)} disabled={updateBookingBtnDisable || this.state.viewStatusProps.checkedin}/>
                                                        }
                                                    </>
                                                    :
                                                    <BookingPartiesPicker onSubmit={(parties, visitors) => this.bookingPartiesSubmitted(parties, visitors)} disabled={updateBookingBtnDisable || this.state.viewStatusProps.checkedin}/>
                                                }
                                            </>
                                            }
                                            {
                                                /* cost codes */
                                                // 1. check user has rights to assign cost codes
                                                (this.local.hasRight("API.Bookings.AssignCostCode") && this.local.hasRight("API.Bookings.V2")) && (
                                                    <>
                                                        <Button fullWidth sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize',marginTop:'0' }} className={this.state.isBookingImmutable ? "row attendes cna" : "row attendes cp"} onClick={() => this.handleCostCodeModal()}>
                                                            <div className="icon-text-inline pl-0">
                                                                <span className="space-icon-item">
                                                                    <SvgIcon component={CostCodeIcon} inheritViewBox sx={{ height: '18px', width: '18px', marginRight: '12px', color: (theme) => theme.palette.text.primary }} aria-labelledby="Cost Code" />
                                                                </span>
                                                                <span className="space-text-item col-text">{this.labels.HubLabelCostCode}</span>

                                                            </div>
                                                            <div className="d-flex">
                                                                <span className="space-text-item mr-3">{this.getCostCodeLabel()}</span>
                                                                <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                                    {Icons.RightArrowIcon}
                                                                </IbssSvgIcon>
                                                            </div>
                                                        </Button>
                                                        <Divider className="my-1"/>
                                                    </>
                                                )
                                            }
                                            
                                            {
                                                (
                                                    swapSpaceAvailable &&
                                                    <>
                                                        <Button fullWidth sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} className={this.state.isBookingImmutable ? "row attendes cna" : "row attendes cp"} onClick={() => this.openDrawer()}>
                                                            <div className="icon-text-inline pl-0">
                                                                <span className="space-icon-item">
                                                                    <SvgIcon component={MapPinIcon} inheritViewBox className="mr-2" sx={{ height: '16px', width: '16px', color: (theme) => theme.palette.text.secondary }} />
                                                                 </span>
                                                                <span className="space-text-item col-text">{this.labels.funcEditLocationSpace_S}</span>

                                                            </div>
                                                            <div className="d-flex">
                                                                <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                                    {Icons.RightArrowIcon}
                                                                </IbssSvgIcon>
                                                            </div>
                                                        </Button>
                                                        <Divider className="my-1"/>
                                                    </>
                                                )
                                            }

                                            {this.state.bookingid && this.state.spacesData[0]?.Meta_Serv_Reqs_Hearing === 1 &&
                                                <>
                                                    <EquipmentsPicker
                                                        disabled={this.state.isBookingImmutable ||this.state.viewStatusProps.checkedin}
                                                        equipmentType="HearingAid"
                                                        buildingId={this.state.buildingid}
                                                        floorId={this.state.primarySpace?.Node_Id ?? 0}
                                                        bookingId={this.state.bookingid}
                                                        bookingStart={DateTime.fromJSDate(this.state.start).toISO()}
                                                        bookingEnd={DateTime.fromJSDate(this.state.end).toISO()}
                                                        bookingName={this.state.name}
                                                        spaceId={this.state.primarySpace?.Space_Id ?? ""}
                                                        spaceName={this.state.primarySpace?.Space_Name ?? ""}
                                                        hearingAidReqs={this.state.primarySpace?.Meta_Serv_Reqs_Hearing ?? 0}
                                                        presentationAidReqs={this.state.primarySpace?.Meta_Serv_Reqs_Presentation ?? 0}
                                                        hearingAidTotal={this.state.hearingAidTotal}
                                                        presentationAidTotal={this.state.presentationAidTotal}
                                                        equipmentSubmitted={() => this.getBookingResources(this.props.match.params.bookingid ?? '')}
                                                        bookedEquipment={this.state.bookingEquipment}
                                                    />
                                                    <Divider className="my-1"/>
                                                </>
                                            }

                                            {!this.state.bookingid && this.state.spacesData[0]?.Meta_Serv_Reqs_Hearing === 1 &&
                                                <div>
                                                    {this.state.spacesData[0]?.Meta_Serv_Reqs_Hearing === 1 &&
                                                        <div className="row">
                                                            <div className="disabled-labels">
                                                                <span className="space-icon-item">
                                                                    <img alt="Hearing Aid" className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} />
                                                                </span>
                                                                <div className="icon-text-inline">
                                                                    <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelHearingConfirmation}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            {this.state.bookingid && this.state.spacesData[0]?.Meta_Serv_Reqs_Presentation === 1 &&
                                                <>
                                                    <EquipmentsPicker
                                                        disabled={this.state.isBookingImmutable ||this.state.viewStatusProps.checkedin}
                                                        equipmentType="PresentationAid"
                                                        buildingId={this.state.buildingid}
                                                        floorId={this.state.primarySpace?.Node_Id ?? 0}
                                                        bookingId={this.state.bookingid}
                                                        bookingStart={DateTime.fromJSDate(this.state.start).toISO()}
                                                        bookingEnd={DateTime.fromJSDate(this.state.end).toISO()}
                                                        bookingName={this.state.name}
                                                        spaceId={this.state.primarySpace?.Space_Id ?? ""}
                                                        spaceName={this.state.primarySpace?.Space_Name ?? ""}
                                                        hearingAidReqs={this.state.primarySpace?.Meta_Serv_Reqs_Hearing ?? 0}
                                                        presentationAidReqs={this.state.primarySpace?.Meta_Serv_Reqs_Presentation ?? 0}
                                                        hearingAidTotal={this.state.hearingAidTotal}
                                                        presentationAidTotal={this.state.presentationAidTotal}
                                                        equipmentSubmitted={() => this.getBookingResources(this.props.match.params.bookingid ?? '')}
                                                        bookedEquipment={this.state.bookingEquipment}
                                                    />
                                                    <Divider className="my-1"/>
                                                </>
                                            }

                                            {!this.state.bookingid && this.state.spacesData[0]?.Meta_Serv_Reqs_Presentation === 1 &&
                                                <div>
                                                    {this.state.spacesData[0]?.Meta_Serv_Reqs_Presentation === 1 && (
                                                        <>
                                                            <div className="row">
                                                                <div className="disabled-labels">
                                                                    <span className="space-icon-item">
                                                                        <img alt="Presentation Aids" className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} />
                                                                    </span>
                                                                    <div className="icon-text-inline">
                                                                        <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelAvailabelconfirmation}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                </div>
                                            }

                                            {this.state.bookingid && (this.state.spacesData[0]?.Meta_Serv_Reqs_Catering === 1) &&
                                                (
                                                    <div>
                                                        <Button fullWidth sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} className={this.state.isBookingImmutable ? "row attendes cna mt-16" : "row attendes cp"} onClick={() => this.state.isBookingImmutable ? () => { } : this.handleCateringRoute()}>
                                                            <div className="icon-text-inline pl-0">
                                                                <span className="space-icon-item">
                                                                    <img alt="Catering" className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} />
                                                                </span>
                                                                <span className="space-text-item col-text">{this.labels.HubLabelCateringTable}</span>
                                                            </div>
                                                            <div className="d-flex">
                                                                {this.state.isCateringEnable ?
                                                                    <div style={{ cursor: "none" }}>
                                                                        <CircularProgress size={20} />
                                                                    </div> :
                                                                    <span className="space-text-item mr-3 text-lowercase"> {this.state.spacesData[0]?.Meta_Serv_Reqs_Catering === 1 ? this.state.cateringTotal + ' ' + this.labels.HubLabelItems : this.labels.HubLabelNone}</span>
                                                                }
                                                                <span className="space-icon-item space-text-item">
                                                                    <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                                        {Icons.RightArrowIcon}
                                                                    </IbssSvgIcon>
                                                                </span>
                                                            </div>
                                                        </Button>
                                                        <Divider className="my-1"/>
                                                    </div>
                                                )
                                            }
                                            {!this.state.bookingid && (this.state.spacesData[0]?.Meta_Serv_Reqs_Catering === 1) &&
                                                (
                                                    <div>
                                                        <div className={this.state.isBookingImmutable ? "row attendes cna" : "row attendes cp"}>
                                                            <div className="disabled-labels">
                                                                <span className="space-icon-item">
                                                                    <img alt="Catering" className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} />
                                                                </span>
                                                                <div className="icon-text-inline">
                                                                    <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelAvailabelCatering}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Divider className="my-1"/>
                                                    </div>
                                                )
                                            }

                                            {this.state.bookingid && (
                                                <>
                                                    <Button fullWidth sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} className="row attendes cp" onClick={() => this.handleShowHideViewStatusModal()}>
                                                        <div className="icon-text-inline pl-0">
                                                            <span className="space-icon-item">
                                                                <div className="pl-0 spaceIcon" />
                                                            </span>
                                                            <span className="space-text-item col-text">{this.labels.HubLabelViewStatus}</span>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="space-icon-item space-text-item">
                                                                <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                                                    {Icons.RightArrowIcon}
                                                                </IbssSvgIcon>
                                                            </span>
                                                        </div>
                                                    </Button>
                                                    <Divider className="my-1"/>
                                                </>

                                            )}
                                            <div className="mt-1">
                                                {(this.state.bookingid) ?
                                                    (
                                                        <div>
                                                            <IbssButton
                                                                className="mb-1"
                                                                variant='contained'
                                                                onClick={() => this.submit()}
                                                                disabled={this.disableUpdateButton}
                                                                fullWidth
                                                                size="large"
                                                            >
                                                                {this.labels.HubButtonUpdateBooking}
                                                            </IbssButton>
                                                            {
                                                                checkInEnabled &&
                                                                <IbssButton
                                                                    className="mb-1"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    fullWidth
                                                                    size="large"
                                                                    onClick={() => this.checkIn()}
                                                                    disabled={!checkInEnabled}
                                                                >
                                                                    {this.labels.HubButtonCheckIn}
                                                                </IbssButton>
                                                            }
                                                            {
                                                                !cancelBookingBtnDisable &&
                                                                <IbssButton
                                                                    className="mb-1"
                                                                    variant="contained"
                                                                    color="error"
                                                                    fullWidth
                                                                    size="large"
                                                                    onClick={() => this.handleShowCancelModal()}
                                                                    disabled={cancelBookingBtnDisable}
                                                                >
                                                                    {this.labels.HubButtonCancelBooking}
                                                                </IbssButton>
                                                            }
                                                            {
                                                                checkoutAvailable &&
                                                                <IbssButton
                                                                    className="mb-1"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    fullWidth
                                                                    size="large"
                                                                    onClick={() => this.checkOut()}
                                                                >
                                                                    {this.labels.HubButtonCheckOut}
                                                                </IbssButton>
                                                            }
                                                        </div>
                                                    )
                                                    :
                                                    (<IbssButton
                                                        variant='contained'
                                                        disabled={this.disableSubmitButton}
                                                        onClick={() => this.submit()}
                                                        fullWidth
                                                        size="large"
                                                    >
                                                        {this.labels.HubButtonConfirmBooking}
                                                    </IbssButton>)
                                                }
                                            </div>
                                        </Card>
                                        {
                                            this.userCanReadTag2Event && this.userCanReadTags && this.state.bookingid.length > 0 &&
                                            <Card style={{ width: '378px', marginLeft: '20px', height: 'fit-content', padding: '24px' }}>
                                                <Typography variant="h5">{this.labels.funcTags_S}</Typography>
                                                <BookingTagPicker
                                                    addTag={(tag: TagToEventTypeView) => this.addTag(tag)}
                                                    removeTag={(tagToEventTypeId: string, tagId: string) => this.removeTag(tagToEventTypeId, tagId)}
                                                    bookingId={this.state.bookingid}
                                                    buildingId={this.state.buildingid}
                                                    nodeId={this.state.nodeId}
                                                    selectedBookingTags={this.state.selectedTags}
                                                />
                                                <IbssButton
                                                    variant='contained'
                                                    onClick={() => this.saveTageUpdates()}
                                                    fullWidth
                                                    size="large"
                                                    className="mt-1"
                                                    disabled={(this.state.selectedTags.length == 0 && this.state.bookingTags.length == 0) || this.state.savingTagUpdates}
                                                >
                                                    {this.labels.funcBookingSaveTags_S}
                                                </IbssButton>
                                            </Card>
                                        }
                                    </Box>
                                </div>
                                <IbssButtonRedo
                                    className="btn-back ml-3 col-text"
                                    variant='contained'
                                    color="secondary"
                                    onClick={() => this.cancel()}
                                >
                                    {this.labels.HubButtonBacklabel}
                                </IbssButtonRedo>
                            </div>

                        </div>
                    </Box>
                </Box>
            </>
        );
    }
}
const mapStateToProps = (state: any) =>
{
    return {
        lightModeTheme: state.lightModeTheme,
        currentPageTitle: state.currentPageTitle,
        identityProvidersStore: state.identityProvidersDetails,
        mainPageTitle: state.mainPageTitle,
        flexMySearchFilterCriteria: state.flexMySearchFilterCriteria,
    };
};
export default connect(mapStateToProps)(EditBooking);

export interface IProps extends RouteComponentProps<IQueryParams>, IPropsFromState
{
    flexMySearchFilterCriteria: ISearchFilterCriteria;
}

export interface IQueryParams
{
    buildingid: string;
    spaceId: string;
    bookingid: string | undefined;
    tab: string;
}

export interface ISearchFilterCriteria
{
    fmsfc_start_date_for_filter_modal: Date;
    End_Date_For_filter_modal: Date;
}

export class State
{
    public isLoading = false;
    public primarySpace: ((Space & { floorName: string }) | null) = null;
    public spacesData = new Array<ISpaceView>();
    public imagePaths = new Map<string, string>();
    public isLoadingToSave = false;
    public start = new Date();
    public end = new Date();
    public isStartValid = true;
    public isEndValid = true;
    public bookingDate = new Date();
    public spaceId = "";
    public editMode = false;
    public name = "";
    public Space_Layout: ISpaceLayout[] = [];
    public SpaceArrangementList: ISpaceArrangement[] = [];
    public timeDifference = "";
    public description = "";
    public onBehalfOf = "";
    public bookingOwnerEmail = "";
    public nodeId = 0;
    public isShowModalAttendees = false;
    public isShowModalLayout = false;
    public isShowModalCancel = false;
    public isVisitor = false;
    public isDelete = false;
    public show = false;
    public _CreatedAt = "";
    public _CreatedBy = "";
    public booking_Parties_array: Attendee[] = [];
    public booking_Parties_Visitors_array: Attendee[] = [];
    public tab = 1;
    public bookingCheckInTime = "";
    public bookingEarlyCheckIn = "";
    public bookingCompletionTime = "";
    public bookingCancelletionTime = "";
    public allAttendees: Attendee[] = [];
    public errors = '';
    public alreadyPresentError = '';
    public isDisabled = true;
    public bookingAutoCheckin = 0;
    public firstNameData = "";
    public onBehalfOfData = { email: "", firstName: "", lastName: "", company: "", isVisitor: false };
    public active = true;
    public Space_Setup = 0;
    public visitorOnBehalfOfData = { Email: "", First_Name: "", Last_Name: "", Company: "", };
    public nonVisitorOnBehalfOfData = { Email: "", First_Name: "", Last_Name: "", Company: "", displayName: "" };
    public noOnBehalfOfSelectedError = "";
    public selectedSpaceLayout = "";
    public selectedSpaceLayoutName = "";
    public selectedSeatingArrangement = "";
    public bookingid = "";
    public isViewStatusShowModal = false;
    public bookingConfirmedModal = false;
    public isPrivate = 0;
    public bookingResponse: any = [];
    public BookedData: any = []
    public costCodeAllocation: string | ICreateV2Booking_CostCodeAllocation[] = '';
    public viewStatusProps = {
        createdAt: DateTime.now(), createdBy: "", bookingid: "", metaExtBookingId: "",
        Space_Layout: "", bookingStatus: "", isactive: false, checkedin: false,
        completed: false, cancelled: false, autocheckin: false, earlycheckin: false,
        lastcheckin: false, bookingCheckInTime: DateTime.now(), bookingCompletionTime: DateTime.now(),
        bookingCancelletionTime: DateTime.now(), bookingCancelledBy: "",
    };
    public buildingid = 0;
    public bookingStatus = "";
    public showSpaceLayout = false;
    public showSpaceArrangement = false;
    public isOnBehalfOfRight = false;
    public timelabel = "";
    public hearingAidTotal = 0;
    public presentationAidTotal = 0;
    public showCostCodeModal = false;
    public costCodes: Array<CostCodeWithAllocation> = [];
    public startIntervalMinutes: string[] = [];
    public endIntervalMinutes: string[] = [];
    public cateringTotal = 0;
    public isCateringEnable = false;
    public cateringOrder: ICateringOrders | undefined;
    public delegatedBy: Array<IDelegate> = [];
    public favourites: Array<IFavouriteUser> = [];
    public isBookingImmutable = false;
    public bookingSlotsPolicy: IBookingSlots;
    public bookingPolicyDescription = "";
    public spaceStatus = "";
    public spaceDetailAccordionExpanded = false;
    public spaceCustomInfo = "";
    public bookingPolicyId = "";
    public selectedSpace: IAvailableSpace;
    public layout: any;
    public useOnBehalfOfCostCodes = 0;
    public bookingIsApproved = 0;
    public addOnlineMeetingLink = false;
    public showCancelSuccessPoup = false;
    public bookingEquipment: IBookingResources[] = [];
    public spaceInfo: ISpaceInfoView[] = []
    public freeFormCostCode = false;
    public availabilityCheckRequired = false;
    public checkingSpaceAvailability = false;
    public spaceAvailabilityStatus: null | 'available' | 'unavailable' = null
    public bookingStart = DateTime.now();
    public bookingEnd = DateTime.now();
    public setupDuration = 0;
    public teardownDuration = 0;
    public selectedAlternativeSpaceId = '';
    public showAlternativeSpaceSelection = false;
    public selectedTags: TagToEventTypeView[] = []
    public bookingTags: TagToEventTypeView[] = []
    public savingTagUpdates = false;
    public bookingAttendance = 0;
    public showChangeBookingTimePopup = false;
    public changeCateringTimePopupMessage = '';
    public showSpaceInfoDialog = false;
    public todayBookings: IBooking[] = [];
    public isBookingsAvailable = false;
    public bookingAttendanceControl: string = BookingAttendanceControl.showAttendanceOptional;

    public isDrawerOpen = false;
    public showAvailableTimelotNote = false;

    constructor()
    {
        this.bookingSlotsPolicy = {} as IBookingSlots;
        this.selectedSpace = {} as IAvailableSpace;

        //Workspaces
        this.imagePaths.set("Desk", "/images/spaceImages/space_desk.png");
        this.imagePaths.set("SitStandDesk", "/images/spaceImages/space_sitstanddesk.png");
        this.imagePaths.set("SpecialistDesk", "/images/spaceImages/space_specialistdesk.png");
        this.imagePaths.set("MeetingRoom", "/images/spaceImages/space_meetingroom.png");
        this.imagePaths.set("MeetingBooth", "/images/spaceImages/space_meetingbooth.png");
        this.imagePaths.set("PhoneBooth", "/images/spaceImages/space_phonebooth.png");
        this.imagePaths.set("FocusBooth", "/images/spaceImages/space_focusBooth.png");
        this.imagePaths.set("Touchdown", "/images/spaceImages/space_touchdown.png");
        this.imagePaths.set("CollaborationSpace", "/images/spaceImages/space_collaborationspace.png");
        this.imagePaths.set("Office", "/images/spaceImages/space_office.png");
        this.imagePaths.set("PrivateRoom", "/images/spaceImages/space_privateroom.png");
        this.imagePaths.set("VCRoom", "/images/spaceImages/space_vcroom.png");
        this.imagePaths.set("Boardroom", "/images/spaceImages/space_boardroom.png");
        this.imagePaths.set("SharedQuietRoom", "/images/spaceImages/space_quietroom.png");
        this.imagePaths.set("LectureTheatre", "/images/spaceImages/space_lecturetheatre.png");

        //Supportspaces
        this.imagePaths.set("InformalLounge", "/images/spaceImages/space_informallounge.png");
        this.imagePaths.set("TrainingRoom", "/images/spaceImages/space_trainingroom.png");
        this.imagePaths.set("BenchSeating", "/images/spaceImages/space_benchseating.png");
        this.imagePaths.set("Storage", "/images/spaceImages/space_storage.png");
        this.imagePaths.set("PrintRoom", "/images/spaceImages/space_printroom.png");
        this.imagePaths.set("Lockers", "/images/spaceImages/space_lockers.png");
        this.imagePaths.set("Stationery", "/images/spaceImages/space_stationery.png");
        this.imagePaths.set("PostRoom", "/images/spaceImages/space_postroom.png");

        //Amenities
        this.imagePaths.set("Reception", "/images/spaceImages/amenity_reception.png");
        this.imagePaths.set("WaitingLounge", "/images/spaceImages/amenity_waitinglounge.png");
        this.imagePaths.set("Catering", "/images/spaceImages/amenity_restaurant.png");
        this.imagePaths.set("Café", "/images/spaceImages/amenity_cafe.png");
        this.imagePaths.set("Kitchenette", "/images/spaceImages/amenity_kitchenette.png");
        this.imagePaths.set("Bar", "/images/spaceImages/amenity_bar.png");
        this.imagePaths.set("LiftLobby", "/images/spaceImages/amenity_liftlobby.png");
        this.imagePaths.set("TreatmentRoom", "/images/spaceImages/amenity_treatmentroom.png");
        this.imagePaths.set("BOHOffice", "/images/spaceImages/space_office.png");
        this.imagePaths.set("Gym", "/images/spaceImages/amenity_gym.png");
        this.imagePaths.set("Changing", "/images/spaceImages/amenity_changing.png");
        this.imagePaths.set("UnisexWCs", "/images/spaceImages/amenity_toilets.png");
        this.imagePaths.set("MaleWC", "/images/spaceImages/amenity_toiletsmale.png");
        this.imagePaths.set("FemaleWC", "/images/spaceImages/amenity_toiletsfemale.png");
        this.imagePaths.set("AccessibleWC", "/images/spaceImages/amenity_toilets.png");
        this.imagePaths.set("BikeStore", "/images/spaceImages/amenity_bikestore.png");
        this.imagePaths.set("FirstAid", "/images/spaceImages/amenity_firstaid.png");
        this.imagePaths.set("MothersRoom", "/images/spaceImages/amenity_mothersroom.png");
        this.imagePaths.set("Security", "/images/spaceImages/amenity_security.png");
        this.imagePaths.set("CoatStorage", "/images/spaceImages/amenity_coatstorage.png");
        this.imagePaths.set("IndoorGarden", "/images/spaceImages/amenity_indoorgarden.png");
        this.imagePaths.set("PrayerRoom", "/images/spaceImages/amenity_prayerroom.png");
        this.imagePaths.set("Prayer", "/images/spaceImages/amenity_prayer.png");
        this.imagePaths.set("CleaningStation", "/images/spaceImages/amenity_cleaningstation.png");
        this.imagePaths.set("PlantRoom", "/images/spaceImages/ancillary_plantroom.png");
        this.imagePaths.set("Riser", "/images/spaceImages/ancillary_riser.png");
        this.imagePaths.set("EquipmentRoom", "/images/spaceImages/ancillary_equipmentroom.png");
        this.imagePaths.set("WellnessRoom", "/images/spaceImages/amenity_wellness.png");
        this.imagePaths.set("Pantry", "/images/spaceImages/amenity_pantry.png");
        this.imagePaths.set("WaterPoint", "/images/spaceImages/amenity_waterpoint.png");
        this.imagePaths.set("ParkingSpace", "/images/spaceImages/amenity_parkingspace.png");
        this.imagePaths.set("ParcelLocker", "/images/spaceImages/amenity_parcellocker.png");
    }
}

export interface ISpaceView extends Space
{
    floorName: string;
}

export interface ISpaceLayout
{
    Space_Id: string;
    Name: string;
    SeatingArrangements: ISeatingArrangement[];
}

export interface ISeatingArrangement
{
    Breakdown: number;
    Capacity: number;
    Setup: number;
    Style: string;
}
export interface IAvailableSpace
{
    nodeId: number;
    spaceId: string;
    imageUrl: string;
    spaceName: string;
    spaceType: string;
    capacity: string;
    floor: string;
    zone: string;
    spaceLayout: string;
    dateRange: string;
    timeRange: string;
    requestedOccurrence: string | null;
    availableOccurrences: string | null;
    cateringAvailable: number;
    presentationAidAvailable: number;
    hearingAidAvailable: number;
    requiresAV: number;
    spaceSetup: number;
    spaceTypeLabel: string;
    datesFound: string[];
    recurringSpace: boolean;
    spaceCustomInfo: string;
    bookingPolicyId: string;
}

export interface ISpaceInfoView
{
    imageUrl: string,
    title: string,
    actionUrl: string,
    availableFrom: DateTime,
    expiryDate: DateTime,
    spaceInfoMessage: string
}

export class TagToEventTypeView
{
    public nodeId = 0;
    public tagToEventTypeId = "";
    public tagId = "";
    public tagType = "";
    public name = "";
    public value = "";
    public icon = "";
    public recordId = "";
    public eventTypeId = 0;

    public static fromTagToEventType(tagToEventType: TagToEventType): TagToEventTypeView
    {
        return {
            nodeId: parseInt(tagToEventType.Node_Id),
            tagToEventTypeId: tagToEventType.Tag2EventType_Id,
            tagId: tagToEventType.Tag_Id,
            tagType: tagToEventType.TagType,
            name: tagToEventType.Name,
            value: tagToEventType.Value,
            icon: tagToEventType.Icon,
            recordId: tagToEventType.Record_Id,
            eventTypeId: tagToEventType.EventType_Id,
        }
    }
}
